<template lang="pug">
.virtual-tour(v-if='tour')
  div Virtual Tour Available
</template>

<script>

export default {
  name: 'VirtualTour',
  props: ['tour'],
  data(){
    return {
    }
  },
  methods: {
    toggleVirtualTour(){
      console.log('toggling');
      this.$refs.tour.classList.toggle('hasTour');
    }
  }
}

</script>

<style lang="scss" scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
.virtual-tour {
  position: absolute;
  right: 0;
  bottom: 48px;
  margin: .4em;
  background: $gg-green;
  color: $gg-lite;
  padding: .2em .4em;
  border-radius: .4em;
}
i {
  color: $gg-white;
  font-size: 1.618em !important;
  &.VirtualTour {
    color: $gg-green;
  }
}
</style>