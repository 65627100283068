<template lang="pug">

.welcome-filter

  //- Bedrooms, Bathrooms
  select(
    v-if="[ 'Bedrooms', 'Bathrooms' ].includes( filter.name )" 
    @change="changeRooms"
    :name="filter.name.toLowerCase()"
    ref="selector"
  )    
    option(
      v-for="option, i in filter.options" 
      :name="filter.name.toLowerCase() + `[${i}]`"
      :selected="option == filter.defaultOption"
      :class="{ active: i == selectedOption }"
      :value="option"
      ) {{ option }} {{ option == 1 ? filter.name.slice( 0, -1 ) : filter.name }}

  //- Price 
  select(
    v-if="filter.name == 'Price'" 
    @change="changePrice"     
    :name="filter.name.toLowerCase()"
    ref="selector"
  )
    option(
      v-for="opt, index in priceOptions"
      :value="opt[1]"
      :selected="index == 2"
      :class="{ active: index == selectedOption }"
    ) {{ opt[0] }}

  //- Footage
  select(
    v-if="filter.name == 'Footage'" 
    @change="changeFootage"
    :name="filter.name.toLowerCase()"
    ref="selector"
  )    
    option(
      v-for="option, i in filter.options" 
      :name="filter.name.toLowerCase() + `[${i}]`"
      :selected="option == filter.defaultOption"
      :class="{ active: i == selectedOption }"
      :value="option"
      ) {{ option }} Sq.Ft.

  //- Time On Market
  select(
    v-if="filter.name == 'Time On Market'" 
    @change="changeTOM"
    :name="filter.name.toLowerCase()"
    ref="selector"
  )    
    option(
      v-for="option, i in filter.options" 
      :name="filter.name.toLowerCase() + `[${i}]`"
      :selected="option == filter.defaultOption"
      :class="{ active: i == selectedOption }"
      :value="option"
      ) {{ option.label }}

</template>

<script>

import { useListingsStore } from '@/store/listings';

export default {
  name: 'WelcomeFilter',
  props: [ 'filter' ],
  data(){
    return {
      listings: useListingsStore(),
      priceOptions: [
        [ 'Under $250,000', [ 0, 100000 ] ],
        [ '$250K-$350K', [ 250000, 350000 ] ],
        [ '$350K-$500K', [ 350000, 500000 ] ],
        [ '$500K-$600K', [ 500000, 600000 ] ],
        [ '$600K-$750K', [ 600000, 750000 ] ],
        [ '$750K-$1M', [ 750000, 1000000 ] ],
        [ '$1M-$2.5M', [ 1000000, 2500000 ] ],
        [ '$2.5M+', [ 2500000 ] ],        
      ],
      selectedOption: 0
    }
  },
  methods: {
    changeRooms( e ){
      const o = e.target[ e.target.selectedIndex ];
      const c = {};      
      // console.log( o.getAttribute( 'name' ), o.value )
      c[ o.name ] = o.value;
      this.listings.changeFilters( c );
      this.filterChange();
    },
    changePrice( e ){      
      const a = e.target[ e.target.selectedIndex ].value.split(',');
      const c = {
        priceMin: a[0],
        priceMax: a[1]
      };      
      this.listings.changeFilters( c );
      console.log( this.listings.filtersChanged );
      this.filterChange();
    },
    filterChange(){
      console.log( 'Filter Changed' );
      this.$emit( 'filterChanged' );
      this.assignSelectedOption();
    },
    assignSelectedOption(){      
      this.selectedOption = this.$refs.selector.selectedIndex;      
    }
  },
  mounted(){
    this.assignSelectedOption();    
  }
}

</script>

<style lang="scss">

.welcome-filter {

  select {    
    padding: .618em;
    margin: .618em auto 1.618em;
    margin-right: 1em;    
    box-shadow: 0 2px 4px transparentize( $gg-green, .4 );
    outline: thick double transparentize( $gg-green, .4 );
    outline: none;
    text-align: center;    
    //border-radius: .618em;
    //background-color: lighten( $gg-gray, 15 );
    background-image: linear-gradient(to bottom, lighten( $gg-gray, 15 ), $gg-lite);
    background-image: linear-gradient(-0deg, lighten( $gg-gray, 13),  $gg-lite, lighten( $gg-gray, 18 ));
    font-size: 1.2em;
    font-family: "proxima-nova", "Proxima Nova", "Open Sans", "Arial", sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $gg-green;
    color: $gg-blue;
    cursor: pointer;
    &:hover {
      background-image: linear-gradient(-0deg, lighten( $gg-gray, 15),  $gg-lite, lighten( $gg-gray, 20 ));
    }

    option {
      margin: .618em;
      font-size: .8em;
      text-align: left;
      &.active {
        //text-align: center !important;
      }
    }
    
  }
}

.screen-tiny .welcome-filter {
  
}

</style>