<style lang="scss">
  @import "../assets/css/app.scss";
</style>

<template lang="pug">

.mls-app(
  ref='container'
  :class="appClassList"
)

  Admin(v-show='app.adminVisible')

  .mls-overlay
    WelcomeOverlay(v-if="app.welcome")
    OverlayPage(v-if="app.overlay")

  .mls-interface

    .mls-top-bar
      .wrap
        TopBar

    .mls-midsection

      .mls-side-bar-left(:class="{ active: app.leftBar }")
        .wrap
          LeftBar

      .mls-main-body

        .mls-map
          .wrap
            Map

        .mls-listings

          ListingsMaster

          

      .mls-side-bar-right(:class="{ active: app.rightBar }")
        .wrap
          RightBar(@close="app.closeSideBars")


    .mls-bottom-bar
      .wrap      
        BottomBar

</template>

<script>

const DEBUG = 0;

import Admin from '@/components/user/Admin.vue';

import TopBar from '@/components/main/TopBar.vue';
import BottomBar from '@/components/main/BottomBar.vue';
import LeftBar from '@/components/main/LeftBar.vue';
import RightBar from '@/components/main/RightBar.vue';

import Map from '@/components/map/Map.vue';

import ListingsMaster from '@/components/listings/ListingsMaster.vue';

import OverlayPage from '@/components/Overlay.vue';
import WelcomeOverlay from '@/components/Welcome.vue';

import { useAppStore } from '@/store/app'; 
import { useListingsStore } from '@/store/listings'; 
import { useMapStore } from '@/store/map'; 

import ListingsObserver from '@/composables/ListingsObserver';

export default {
  name: 'Main',
  components: {
    Admin,
    TopBar,
    BottomBar,
    LeftBar,
    RightBar,
    Map,
    ListingsMaster,
    OverlayPage,
    WelcomeOverlay
  },
  data(){
    return {
      app: useAppStore(),
      listings: useListingsStore(),
      observer: new ListingsObserver(),
      map: useMapStore(),
      touchStartX: undefined,
      overlay: !false
    }
  },
  computed: {
    appClassList(){
      const a = [];
      a.push( `layout-${this.app.layout}` );
      a.push( `screen-${this.app.viewSize}` );
      if( this.app.overlapListings ) a.push( 'listings-overlap' );
      return a.join(' ');
    }
  },
  methods: {
    
    async updateFilters( filterSettings ){
      // console.log(filterSettings);
      if( !this.filterSettings ) this.filterSettings = {};
      for( const name in filterSettings ){
        if( filterSettings[name] ) this.filterSettings[name] = filterSettings[name];
      }
      if( DEBUG > 3 ) console.log('new filter options:',this.filterSettings);
      this.closeSideBars();
      await this.listings.fetch( filterSettings, this.appListingsLimit );
    },    
    
    async setupListingsObservers(){
      
      this.observer.setup();

      this.app.listingsObserver = this.observer;

      const lo = this.observer.create( .8 );

      let currentMarkers = [];

      const self = this;

      await this.map.ready; // make sure to await map before targeting map features
      
      this.map.event( 'idle', () => {

        lo.registerIntersectionExchange({
          name: 'Map Icon',
          before: () => {        
            if( currentMarkers && currentMarkers.length ){
              try {
              for( const m of currentMarkers ) m.restoreMarker();
              } catch(e){
                if( DEBUG > 2 ) console.log( currentMarkers, e );
              }
            }
            currentMarkers = [];
          },
          callback: ( e ) => {
            if( DEBUG > 1 ) console.log( 'listing dataset', e.target.dataset );
            currentMarkers.push( self.map.changeMarkerIcon( e.target.dataset.mls, 'blue' ) );
          }
        })

        //change 1st listing regardless (if any exist)
        const l = document.querySelector('.listing');
        if( !l ) return;
        currentMarkers.push( self.map.changeMarkerIcon( l.dataset.mls, 'blue' ) );

      })

    },

    async loadInitialListings(){
      await this.listings.fetch( 
        this.listings.filterDefaults,
        20 
        //this.app.listingsLimit || 15
      )
    },

    showApp(){
      document.querySelector('.mls-app').style.opacity = 1;
    },

    removeLoader(){

      // remove loading animation container

      const l = document.querySelector('.vue-loading');
      
      if( !l ) return this.showApp();

      l.style.transition = 'opacity .618s linear';
      l.style.opacity = 0;      
      setTimeout( () => { l.remove(); }, 618 );

      return this.showApp();

    }

  },

  

  async created(){    
  },

  async mounted(){

    if( DEBUG > 2 ) console.log( 'Main.vue Mounted;', this.listings.ready );

    let fallbackLoadListings = null;

    // this.app.showAdmin()

    // this.app.welcome = false;

    // fetch initial listings
    if( !this.app.welcome ){
      await this.loadInitialListings();      
    } else {
      if( typeof this.listings.ready == 'undefined' ){        
        fallbackLoadListings = setTimeout( this.loadInitialListings, 30000 );
      } else {
        if( DEBUG ) console.log( 'Main component is waiting for listings to be ready' );
      }
    }

    // command map to update when listings do
    this.listings.on( 'update', this.map.update );

    // call early just to improve FOUSC
    this.app.changeScreenSize();

    const self = this;

    // updateView should be called on component mount, on window resize, and when app layout changes
    const updateView = () => {

      if( DEBUG > 1 ) console.log( 'Main component: updateView called' );

      this.app.changeScreenSize();

      if( this.app.layout == 'vertical' ){        
        // run reversals in case horizontal functions were used
        //setTimeout(() => {
          this.listings.fixListingsView();
          this.app.modListingsHeight( true );
          this.listings.extendListingsContainerWidth( true );          
        //}, 1 );
      }

      if( this.app.layout == 'horizontal' ){
        
        // timeout equal to "nextTick", keep to ensure things settle before adjustments
        setTimeout(() => {
          this.listings.fixListingsView();        
          this.listings.extendListingsContainerWidth();          
          this.listings.determineHorizontalColumnRepeater(); 
          // make status same size as listing
          this.app.normalizeListingsStatus();
          setTimeout( ()=>{
            // set .mls-listings height to listing height
            this.app.modListingsHeight(); // has issue if loads too quick...
          },200);
        }, 1 );
      
      }

    } // end updateView

    // perform the following any time map view changes
    this.listings.on( 'filterByBounds', () => {
      if( self.app.layout == 'horizontal' ) this.listings.extendListingsContainerWidth();
      else this.listings.extendListingsContainerWidth( true );
      this.app.statusMessage = `${this.listings.active.length} listings in view.`;
    })

    // initial view update once listings are fetched/ready
    // await this.listings.ready;
    await this.map.ready; // use map -- more bulletproof
    
    if( fallbackLoadListings ) clearTimeout( fallbackLoadListings );

    updateView();

    setTimeout( this.removeLoader, 400 );

    // update on resize
    window.addEventListener( 'resize', updateView );

    // update view when triggered
    this.app.on( 'updatedView', updateView );

    this.listings.on( 'update', () => {
      setTimeout( updateView, 250 );
    });

    // update if user changes layout
    // this.$watch(
    //   () => this.app.layout,
    //   () => updateView() 
    // );

    this.setupListingsObservers();

    // remove loading animation container after everything's loaded
    let removeLoaderIn = 2500;
    //removeLoaderIn = 0;
    let loading = document.querySelector('.vue-loading')
    if( loading ){
      loading.classList.add('complete');
      setTimeout(() => {
        loading.remove();
      }, removeLoaderIn );
    }

  },

  unmounted(){
    window.removeEventListener( 'resize', this.changeScreenSize );
    // this.$destroy();
  }

}

</script>


<style lang="scss">

// .in-view {
//   box-shadow: 0 0 4px red;
// }
.mls-app {
  // opacity: 0;
}

</style>