<template lang="pug">

.mls-filter-top(
  v-if="!filter.inactive"
)
  span {{filter.name}}
  select(v-model="defaultOption" @change="changeFilterOption")
    option(
      v-for="option in filter.options"
      value='{{option}}'
    ) {{option}}

</template>

<script>

export default {
  name: 'FilterTop',
  props: [ 'filter' ],
  data() {
    return {
      defaultOption: this.filter.options[0]
    }
  },
  methods: {
    changeFilterOption(){

    }
  }
}

</script>

<style lang="scss">

</style>
