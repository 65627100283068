const DEBUG = 3;

import { defineStore } from 'pinia'

import { useUserStore } from '@/store/user';

import ui from './ui';

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      debug: 1,
      user: useUserStore(), 
      layouts: [ 'horizontal', 'vertical' ],    
      layout: 'vertical',
      overlapListings: false, // @DISCONTINUED_FEATURE | when true, map fills main-body and listings overlap
      _listingsObserver: {
        promise: null,
        success: null,
        failure: null
      },
      listingsPerPage: 15,    
      adminVisible: false,      
      overlay: false,
      listingsLimit: 100, // the amount to pull in a single fetch
      statusMessage: '',
      listingsInfo: '',
      showDevBits: false, 
      eventStore: {},
      leftBar: false,
      rightBar: false,
      pageNumber: 1,
      listingsCollapsed: false,
      viewSize: null,
      sizeMods: {
        tv: {
          startAt: 3000,          
        },
        huge: {
          startAt: 2000,
        },
        large: {
          startAt: 1440,
        },
        medium: {
          startAt: 800,
        },
        small: {
          startAt: 400,          
          defaultLayout: 'vertical',
          defaultOverlap: false
        },
        //anything less = "tiny"
        tiny: {
          defaultLayout: 'horizontal',
          defaultOverlap: false
        }
      },
      layoutForced: false,      
      welcome: true, // determines if welcome overlay is visible
      firedConfetti: false, // so it only fires once
    }
  },
  actions: {

    ...ui,

    on( event, callback ){
      if( this.eventStore[ event ] ) this.eventStore[ event ].push( callback );
      else this.eventStore[ event ] = [ callback ];
      if( DEBUG > 3 ) console.log( `${event} registered` );            
    },

    trigger( event, data ){
      if( this.eventStore[ event ] ){
        for( const fn of this.eventStore[ event ] ){
          fn( data );
        }
        if( DEBUG > 2 ) console.log( `${event} triggered` );            
      }
    },

    /**
     * @method setListingsObserver
     * @param {IntersectionObserver} listingsObserver
     * called once to hoist listingsObserver to app store
     */

    setListingsObserver( lo ){
      if( !this._listingObserver ) this._listingsObserver = lo;
    },

    /**
     * @method toggleLeftBar
     * toggles left bar visibility
     */

    toggleLeftBar(){
      if( DEBUG > 2 ) console.log( 'Toggling left bar' );
      this.leftBar = !this.leftBar;
    },

    /**
     * @method toggleRightBar
     * toggles right bar visibility
     */

    toggleRightBar(){
      if( DEBUG > 2 ) console.log( 'Toggling right bar' );
      this.rightBar = !this.rightBar;
    },

    /**
     * @method closeSideBars
     * closes both side bars
     */

    closeSideBars(){
      if( DEBUG > 2 ) console.log( 'Closing sidebars' );
      this.leftBar = false;
      this.rightBar = false;
    },

    /**
     * @method toggleListings
     * toggles visibility of listings
     */

    toggleListings(){
      if( DEBUG > 2 ) console.log( 'Toggling listings' );
      this.listingsCollapsed = !this.listingsCollapsed;
    },

    /**
     * @method changePage
     * @param {number} pageNumber 
     * goes to a specific page if pagination is in use
     */

    changePage( pageNumber ){
      console.log( 'Changing to page', pageNumber );
      this.pageNumber = pageNumber;
    },

    /**
     * @method showAdmin
     * makes admin panel visible
     */

    hideAdmin(){
      this.adminVisible = false;
    },

    /**
     * @method showAdmin
     * makes admin panel visible
     */

    showAdmin(){
      if( !this.firedConfetti ) this.fireConfetti();
      this.adminVisible = true;
    },

  }
})