<template lang="pug">

-
  var sidebar = {
    'Account Details': {},
    'Saved Listings': {},
    'Current Paperwork': {}
  };

mixin admin-sidebar( config )
  ul
    for data, label in config
      li(@click="")=label    


mixin upcoming-feature( label )
  .mls-admin-info-modal
    .title
      h4=label
    .info
      p #{label} will be displayed here.

.mls-admin

  .registration-shell(v-if="showOverlay" )
    Confetti(style="z-index: -1; position: relative")
    Registration(
      @closeOverlay="closeOverlay"
      @openOverlay="openOverlay"
      @hideAdmin="app.hideAdmin"
      @signedOut="signedOut"
      style="z-index: 1; position: relative"
    )

  .exit-button(@click='app.hideAdmin')
    span.icon x

  .wrap.xcontent-styles

    .mls-admin-header
      h1 Admin Panel
      p Manage your account, listings, and more.

    .mls-admin-main

      //-.mls-admin-sidebar
        +admin-sidebar( sidebar )
        .logout-button
          button(@click='logOut') Log Out      

      .mls-admin-content

        .mls-admin-content-container

          #account-details.mls-admin-info-modal
            AccountDetails
            //@changeUserData="updateUserData"


          #saved-listings.mls-admin-info-modal

            .title

              h4 Saved Listings

            .info
              
              .saved-listings(v-if="user && user.savedListings && user.savedListings.length > -1")

                p(v-if="user.savedListings.length > 0")
                  | Found {{ user.savedListings.length }} saved listings. 
                  button.view-saved-listings(@click="fetchSavedListings()") 
                    | {{ showingListings ? "Hide" : "Show" }} Listings

                //-p(v-for="listing in savedListings")
                  | listing

                .actual-listings(v-if="showingListings")
                
                  Listing(
                    v-for="listing in savedListings"
                    :listing="listing"
                    :autoInitPhotoViewer="1"
                  )
                
              p(v-else)
                | Use the 
                i.fa.fa-heart.c2
                |  on any listing to save it here.

          #current-paperwork.mls-admin-info-modal
            .title
              h4 Current Paperwork
            .info
              p It appears you have no pending documents. 
              .content-styles(style='text-align:center')            
                
                img(src='https://www.alexsellsrichmond.com/files/media/png/glaser-group-web-2022.png')

                p.italic Contact our Realtors
                  sup &reg;
                  |  to get started!

          
          .logout-button(style='text-align: right')
            button(@click='logout()') Log Out

    .mls-admin-footer
      .close-admin-panel(@click='app.hideAdmin')
        h4 Close Admin Panel

</template>


<script>

const DEBUG = 0;

import Registration from './Registration.vue';
import AccountDetails from './AccountDetails.vue';
import Confetti from './Confetti.vue';

import { useAppStore } from '@/store/app'; 
import { useUserStore } from '@/store/user'; 
import { useListingsStore } from '@/store/listings'; 

import Listing from '@/components/listings/listing/Listing.vue';


const $ = require('jquery');
import { nextTick } from 'vue';

export default {

  name: 'Admin',

  components: {
    Registration,
    AccountDetails,
    Listing,
    Confetti
  },

  props: [ 'visible' ],

  data(){
    return {
      showOverlay: false,
      user: useUserStore(),
      mainHeight: 0,
      listings: useListingsStore(),
      savedListings: [],
      app: useAppStore(),
      showingListings: false
    }
  },

  computed: {   
  },

  async mounted(){
    
    if( this.user.name == '' ) this.showOverlay = true;
    if( this.user && this.user.savedListings && this.user.savedListings.length ) this.fetchSavedListings();
        
  },

  watch: {
    visible(){
      this.fixAdminMain();
    }
  },

  methods: {

    openOverlay(){
      this.showOverlay = true;
    },

    closeOverlay(){
      this.showOverlay = false;
    },

    getUser( prop, placeholder ){
      if( this.user[prop] && this.user[prop] !== '' ) return this.user[prop];
      return placeholder || '<unknown>';
    },

    //@TODO: sanitize value
    setUser( prop, value ){
      try {
        if( this.user[prop] ) this.user[prop] = value;
      } catch( e ){
        if( DEBUG ) console.log( e );
      }
    },

    updateUserData( userData ){
      if( DEBUG ) console.log( 'updateUserData called', userData );
    },

    async fetchSavedListings(){
      if( this.showingListings ) return this.showingListings = false;
      console.log( 'Fetching saved listings' );
      const listings = await this.listings.fetchByMlsIds( this.user.savedListings );
      if( DEBUG ) console.log( 'Saved listings fetched', listings );      
      this.savedListings = this.listings.processListingImages( listings );  
      this.showingListings = true;
    },

    async fixAdminMain(){  
      await nextTick();      
      const main = $('.mls-admin-main');
      const footer = $('.mls-admin-footer');
      var top = main[0].offsetTop;
      var bottom = footer[0].offsetTop;
      var height = window.innerHeight;
      var spread = height - bottom;
      var fix = ( height - ( top + spread ) );
      console.log( 'checking height, came up with', fix );
      if( fix ){
        if( fix == this.mainHeight ) return;
        this.mainHeight = fix;
        if( DEBUG > 2 ) console.log( 'setting admin main height to', this.mainHeight );
        //main.css( 'max-height', this.mainHeight );
      }
      return fix;
    },

    logout(){
      this.user.signOut();
      this.openOverlay();
    },

    signedOut(){
      $('.mls-admin-content-container').scroll(0);
      this.app.hideAdmin();
      this.user.signedOut = false; // reset to go again
    }

  }
}

</script>

<style lang="scss">

$height-adjusted-1: calc( 100% - 2em );
$height-adjusted-2: calc( 100% - 3.236em );

.mls-admin {

  position: fixed;
  background: transparentize( $gg-gray-dark, .12 );
  z-index: 1000; //1000 beats overlay, which is 999
  width: 100%;
  height: 100%;  

  .wrap {
    // padding: 0.618rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "mls-admin-header"
      "mls-admin-main"
      "mls-admin-footer"
    ;
    // //height: 100vh;    
    // width: 100%;
    // width: calc( 100% - 2em );
    // height: auto;
    max-height: $height-adjusted-1;    
    margin: 1em;
    //border-radius: .618em .618em 0 0;
    border-radius: .618em;
    overflow: hidden;
    // display: flex;
    
  }

  &-header {
    border-bottom: 1px solid $gg-gray;
    background-color: $gg-blue;
    color: $gg-lite;
    padding: 1em;
  }

  &-main {
    // padding: 0.618rem;
    // display: grid;
    // grid-template-columns: .33fr .66fr;
    // grid-template-rows: 1fr;
    // grid-template-areas:
    //   "mls-admin-sidebar mls-admin-content"
    // ;    
    display: flex;
    flex-grow: 1;
    background-color: $gg-lite;
    width: 100%;
    //height: calc( 100% - 2em );
    overflow-y: hidden;
    //max-height: 440px; //temp for testing
  }

  &-sidebar {
    //display: flex;
    min-width: 200px;
    border-left: 1px solid $gg-gray;
    background-color: $gg-gray;
    //background-color: orange;
    //max-height: $height-adjusted-1;
    max-height: inherit;
    ul {
      margin: 0;
      flex-grow: 1;
    }
    li {
      list-style: none;
      padding: 1rem;
      background: $gg-green;
      color: $gg-lite;
      // border: 1px solid $gg-gray;
      margin: 1px;
      cursor: pointer;
      &:hover {
        background-color: lighten( $gg-green, 8 );
        color: darken( $gg-lite, 80 );
      }
    }
    .logout-button {
      // background-color: yellow;
      margin-top: auto;
      button {
        display: block;
        padding: .618em;
        //@TODO: reenable this for prod -- vue wont stfu
        // max-width: -moz-available;          
        // max-width: -webkit-fill-available;  
        // max-width: fill-available;
        width: stretch;
        margin: 1px;
        margin-top: 1px;
        cursor: pointer;
      }
    }
  }

  &-content {    
    max-height: inherit;
    flex-grow: 1;
  }
  
  &-content-container {
    padding: 0.618em;
    overflow-y: scroll;
    max-height: 100%;
  }

  &-info-modal {
    background: $gg-gray;
    margin-bottom: 1em;
    border-radius: .304em;
    overflow: hidden;
    .title {
      background: $gg-dark;
      color: $gg-lite;
      padding: .618em;
    }
    .info {
      padding: .6472em;
      .key { text-transform: capitalize; }
      .prop {}
    }
  }

  &-footer {
    // position: absolute;
    bottom: 1em;
    // width: calc( 100% - 2em );
    background: red;
    color: white;
    text-align: center;
    border-radius: 0 0 .618em .618em;
  }

  .close-admin-panel {
    cursor: pointer;
    user-select: none;
    padding: .618em;
    z-index: 1;
  }

  .exit-button {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    background: red;
    color: white;
    border-radius: 14px;
    margin: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    text-align: center;
    .icon {
      display: block;
      position: absolute;
      top: 1px;
      right: 7px;
      height: 24px;
      align-self: center;
      justify-content: center;
      cursor: pointer;
      font-size: 20px;
      img {
        filter: invert(.8) saturate(80) hue-rotate(55deg)
      }
      &:hover {
        img {
          filter: invert(.9) saturate(90) hue-rotate(55deg)
        }
      }
    }
  }

  .registration-shell {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
  }

  #saved-listings {
    position: relative;
    .listing {
      background: $gg-lite;
      padding: 0;
      margin: 1em auto 1.618em;
      width: 100%;
      // max-width: 500px;
      .info {
        padding: 0;
      }
      // temporary fixes @TODO FIX THESE
      .accent-bar, .virtual-tour {
        display: none; 
      }
      .block-top .photo-wrap .photo {
        border: none;
      }
    }
  }

  button.view-saved-listings {
    padding: 4px;
    margin-left: .618em;
  }

}

.screen-small {
  .mls-admin {
    font-size: 2em;
  }
}

.screen-tiny {
  .mls-admin {
    font-size: 1.618em;
  }
}

</style>