<template lang="pug">
.listing-status(v-if='listingStatus' :class="statusClass")
  span.listing-status-text {{listingStatus}}
</template>

<script>

export default {
  name: 'ListingStatus',
  props: ['listingStatus'],
  computed: {
    statusClass(){
      let c = '';
      if( this.listingStatus )
        c = this.listingStatus.toLowerCase();
      return c;
    }
  }
}

</script>

<style lang="scss" scoped>
.listing-status {
  position: absolute;
  left: 0;
  top: 0;
  &-text {
    position: relative;
    top: 18px;
    left: -36px;
    text-align: center;
    display: inline-block;
    background: $gg-green;
    color: $gg-lite;
    padding: .4em 36px .4em;
    transform: rotate(-45deg);
    transform-origin: center;
    text-transform: uppercase;
  }
  &.pending {
    .listing-status-text {
      background: $gg-dark;
      color: $gg-green;
      top: 23px;
      left: -36px;
    }
  }
  &.new {
    .listing-status-text {
      top: 16px;
      left: -36px;
      padding: .4em 48px .4em;
    }
  }
}
</style>