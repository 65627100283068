<template lang="pug">

.listings-filters-top
  FiltersTop

</template>

<script>

import FiltersTop from '@/components/filters/FiltersTop.vue'

export default {
  name: 'ListingsFiltersTop',
  props: {
  },
  components: {
    FiltersTop
  }
}

</script>
