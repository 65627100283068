<template lang="pug">

.title
  h4 Account Details

.info
  AccountDetail( 
    v-for="detail, i in details"
    :key="detail + i", 
    :detail="detail"
    @valueChanging="notifyValueChanging"    
  ) 

.update-status(v-if="updateStatus != ''") 
  span.update-status-message {{ updateStatus }}  
  .buttons
    button(@click='changeUserData' :disabled="updateDisabled") Update Details

</template>

<script>

import AccountDetail from './AccountDetail'
import * as DOMPurify from 'dompurify';
import debounce from 'lodash/debounce';
import { useUserStore } from '@/store/user';
import { watch } from 'vue';

import { db } from '@/firebase';
import { ref, update } from 'firebase/database';

export default {
  name: 'AccountDetails',
  components: {
    AccountDetail
  },
  //props: [ 'user' ],
  data(){
    return {
      user: useUserStore(),
      details: [ 'name', 'address', 'email', 'phone' ],
      updateStatus: '',
      debouncer: null,
      transmissionDelay: 2500, // the delay between input activity and firebase writes
      clearStatusTimer: 3000,
      autosend: false,
      updateDisabled: false,
    }
  }, 
  methods: {

    validateEmail( email ){
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Test the email against the regex
      return emailRegex.test( email );
    },

    validatePhone( phoneNumber ){
      // Remove non-digit characters
      const cleanedNumber = phoneNumber.replace( /\D/g, '' );
      // Check if the cleaned number has 10 digits and starts with 2-9
      const isValid = /^[2-9]\d{9}$/.test( cleanedNumber );
      return isValid;
    },

    formatPhone( phoneNumber ){

      // Remove non-digit characters
      const cleanedNumber = phoneNumber.replace( /\D/g, '' );

      // Check if the cleaned number has 10 digits
      if( /^\d{10}$/.test( cleanedNumber ) ){
        // Format the number as (XXX) XXX-XXXX
        return `(${cleanedNumber.substring(0, 3)}) ${cleanedNumber.substring(3, 6)}-${cleanedNumber.substring(6)}`;
      } else {
        // Return the original input if it's not a valid phone number
        return phoneNumber;
      }
    },

    notifyValueChanging( active ){
      //console.log( active );
      this.updateStatus = active ? 'Changes pending...' : '';
      // console.log( this.user );
      const validEmail = this.validateEmail( this.user.email );
      const validPhone = this.validatePhone( this.user.phone );
      if( this.user.email != '' && !validEmail ){
        this.updateDisabled = true;
        this.updateStatus = 'Invalid email detected.';
        return;
      } 
      if( this.user.phone != '' && !validPhone ){
        this.updateDisabled = true;
        this.updateStatus = 'Invalid phone number detected.';
        return;
      } else {
        this.user.phone = this.formatPhone( this.user.phone );
      }
      if( this.updateDisabled ) this.updateDisabled = false;
      
      // console.log( 'Valid emial?', validEmail );
      if( this.debouncer ){
        this.debouncer.cancel();
        this.debouncer = null;
      }
      if( active && this.autosend ){
        this.debouncer = debounce( this.changeUserData, this.transmissionDelay );
        this.debouncer();
      } else {
        this.updateStatus = 'Changes pending: press update.';
        return;
      }
    }, 

    informChangesSaved(){
      this.updateStatus = 'Changes saved!';
      // clear notification after X seconds
      setTimeout(() => {
        this.updateStatus = '';
      }, this.clearStatusTimer );
    },

    changeUserData(){
      // console.log( 'changeUserData called' );
      this.updateStatus = 'Processing (please do not close browser)';
      // update data in firestore
      // console.log( 'updating firestore db' );
      const userRef = ref( db, `users/${this.user.uid}` );      
      const newData = this.user.convertStateToObject();
      update( userRef, newData ).then( this.informChangesSaved );
    },

    userUpdatedValue( key, value ){
      const sval = DOMPurify.sanitize( value );      
      // console.log( `updating ${key} to ${sval}` );
      this.userClone[ key ] = sval;
      if( !this.autosend ){
        this.updateStatus = 'Click submit to save.';
        return;
      }
      if( this.debouncer ) this.debouncer.cancel();
      this.debouncer = debounce( this.changeUserData, this.transmissionDelay );
    }

  },

  watch: {
    user( newVal ){
      console.log(  newVal );
    }
  }

}

</script>

<style lang="scss" scoped>

.update-status {
  padding: 1em;
  text-align: right;
}
.update-status-message {
  display: inline-block;
  margin-bottom: 12px;
}
.buttons {

  button {
    padding: 1em;
  }

}

</style>