const DEBUG = 0; 

import { useAppStore } from '@/store/app';

const app = useAppStore();

export default {
  
  /**
   * @method scrollToListing
   * scrolls .mls-listings-wrap to a specific .listing based on mls ID number
   * @param {number} mlsId    
   */

  scrollToListing( mlsId ){
    if( DEBUG > 1 ) console.log( 'Scrolling to listing' );
    const listings = document.querySelector( '.mls-listings-wrap' );
    const l = listings.querySelector( '#listing' + mlsId );
    if( l ){
      if( DEBUG > 1 ) console.log( 'listing' + mlsId + ' found; scrolling now.' );
      if( this.app.layout == 'vertical' ){
        const y = l.offsetTop;
        listings.scrollTo( 0, y );
      } else {
        const x = l.offsetLeft;
        // listings.scrollTo( x, 0 );
        document.querySelector('.listings').scrollTo( x, 0 );
      }
    } else {
      if( DEBUG > 1 ) console.log( 'listing' + mlsId + ' not found; attempting to find/fetch.' );
      //const pageNum = this.listings.metadata.pageNumber;
    }
  },

  
  /**
   * @method extendListingsContainerWidth
   * makes .listings width .listing width x length (+1 if normalized .listing-status) for horizontal scrolling
   * @DEVNOTE horizontal layout only   
   */

  extendListingsContainerWidth( reverse ){

    const listings = document.querySelector( '.listings' );

    if( !listings ) return; // to prevent error when there are no listings

    if( DEBUG > 3 ) console.log( reverse ? 'Undoing listings container extension' : 'Extending listings container' );
    
    let li = document.querySelector( '.listing' );

    if( reverse ) li = false; // switch to width 100 for reversal

    if( !li ){
      listings.style.width = '100%';
      return;
    }

    //console.log( 'idle, checking width' );

    const w = li.clientWidth;

    const l = this.active.length + 1; // +1 because normalized .listing-status

    //console.log( 'len', l, 'width' , w, 'total', ( w * l ) );

    document.querySelector( '.mls-listings-wrap' ).style.width = ( w * l ) + 'px';

    listings.style.width = ( w * l ) + 'px';

  },

  
  /**
   * @method fixListingsView
   * pushes .listings down by listings sorter (if present) and leaves room for pagination (if present)     
   */

  fixListingsView(){
    if( DEBUG > 2 ) console.log( 'Fixing listings view' );
    const wrap = document.querySelector('.mls-listings-wrap');
    const sorter = document.querySelector('.mls-listings-sorter');
    const pagination = document.querySelector('.listings-pagination');
    const sh = sorter ? sorter.offsetHeight : 0;
    const ph = pagination ? pagination.offsetHeight : 0;
    let diff = sh + ph;
    if( DEBUG > 2 ) console.log( 'adjusting listingsWrap by', diff, `(${sh}, ${ph})` );
    if( !diff || !wrap ) return;
    wrap.style.height = `calc(100% - ${diff}px)`
    wrap.style.marginTop = sh + 'px';
  },

  /**
   * @method allowHorizontalScroll
   * translate scrollwheel from Y to X for simple horizontal scrolling
   * @NOTE can scroll by .listing width instead of delta
   * @DEVNOTE horizontal layout only
   */

  async allowHorizontalScroll(){

    //console.log( this.app.layout );

    if( app.layout == 'vertical' ) return;

    if( DEBUG > 3 ) console.log( 'Checking fors horizontal scroll' );

    await this.ready;      

    const scrollContainer = document.querySelector('.mls-listings-wrap');

    const listing = document.querySelector('.listing');

    if( !listing ) return; 

    if( DEBUG > 3 ) console.log( 'Allowing horizontal scroll' );

    const deltaModifier = listing.clientWidth > 20 ? listing.clientWidth : 500;

    // scrollContainer.addEventListener

    function handleWheel( event ){

        const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        scrollContainer.scrollLeft -= delta * deltaModifier; // Adjust the multiplier as needed
        event.preventDefault();

    }

    if( scrollContainer.addEventListener ){
        // For modern browsers
        scrollContainer.addEventListener( 'wheel', handleWheel, { passive: !true }) ;
    } else {
        // For older browsers
        scrollContainer.attachEvent( 'onmousewheel', handleWheel );
    }

  }, 

  /**
   * @method touchStart
   * indicates touch has started and records starting point (clientX)
   */

  touchStart( e ){
    if( DEBUG > 4 ) console.log( 'touchStart initiated', e.touches[0].clientX );
    this.touchStartX = e.touches[0].clientX;
  },

  /**
   * @method touchMove
   * similar to allowHorizontalScroll, translates X movements by .listing width for .mls-listings-wrap
   */
  

  touchMove( e ){

    if( this.touchStartX !== undefined ){

      if( DEBUG > 2 ) console.log( 'touchMove: Moving' );

      // const wrap = document.querySelector('.mls-listings-wrap');
      const wrap = document.querySelector('.mls-listings-wrap .listings');
      const wrap2 = document.querySelector('.mls-listings-wrap');

      if( !wrap ) console.log( 'NO WRAP' );      

      // Calculate the horizontal distance moved during the touch
      const touchDeltaX = e.touches[0].clientX - this.touchStartX;

      if( DEBUG > 3 ) console.log( 'deltaX', touchDeltaX );

      // Check if the movement is predominantly horizontal
      if( Math.abs(touchDeltaX) > 5 ){

        const w = document.querySelector('.listing').clientWidth;

        if( DEBUG > 2 ) console.log( `Moving scrollbar ${touchDeltaX > this.touchStartX ? w : -w} px` );

        // Adjust the scroll position based on the touch movement
        if( touchDeltaX > this.touchStartX ){
          wrap.scrollLeft += w;
          wrap2.scrollLeft += w;
        } else {
          wrap.scrollLeft += w; //-
          wrap2.scrollLeft += w; //-
        }
      }

      // Reset the initial touch position
      this.touchStartX = undefined;

    }

  },

  /**
   * @method determineHorizontalColumnRepeater
   * for screens under 500px, sets grid repeat value on xxx
   * @NOTE: for horizontal layout only
   */

  determineHorizontalColumnRepeater(){
    if( window.innerWidth < 500 ){
      const l = document.querySelector( '.mls-listings .listings' );        
      if( l ){
        const w = l.querySelector('.listing').offsetWidth;
        if( DEBUG > 2 ) console.log( 'Mobile detected: setting horizontal columns to', w );
        l.style.gridTemplateColumns = `repeat( auto-fill, ${w}px )`;
      } 
    }
  },  

  showListing( listing, photoIndex ){
    // console.log( 'hi', listing, photoIndex ); return;
    this.selected = listing;
    if( photoIndex ) this.selected.currentPhoto = photoIndex;
    this.app.overlay = true;
  },

  hideListing(){
    this.listings.selected = null;
    this.app.overlay = false;
  },


}