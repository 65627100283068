<template lang="pug">

- if( demoImage ) listing.image = demoImage;

.listing-top

  .listing-overlay
    FavoriteListing(:favorite="userFavorite" @toggledFavorite="toggleFavoriteStatus")
    VirtualTour(:tour='+listing.virtualTour')
    OpenHouse(:openHouse='listing.openHouse')
    ListingStatus(:listingStatus='listing.status')


  .photo-wrap
    PhotoViewer(
      :listing="listing"
      :autoInit="autoInitPhotoViewer"
      @showListing="( i ) => { $emit( 'showListing', i ) }"
    )
      //- @showListing="( i ) => { this.$emit( 'showListing', i ) }"
    //- @click="$emit( 'showListing' )"
    //- defaultImage = generic "No image available" graphic
    .photo-stock(
      :style="{ backgroundImage: `url(${listing.defaultImage})` }"
      ref='stockPhoto'      
    )
      //- placeholderImage = pretty image of house, good image ratio for templating
      img(:src='placeholderImage' style='opacity: 0')
    

  .accent-bar.style-overlay
    .wrap
      .left
        .address
          .line
            .street {{listing.street}}
            br
            .zone {{listing.city}}, {{listing.state}} {{listing.zip}}
      .right
        span.price {{price}}
        br
        span(style='opacity: .5').mlsID 
          span.extra MLS 
          | #
          span.mls-number {{listing.mls}}
</template>

<script>

import PhotoViewer from './photoViewer/PhotoViewer.vue';
//

import FavoriteListing from './overlay/FavoriteListing.vue';
import VirtualTour from './overlay/VirtualTour.vue';
import OpenHouse from './overlay/OpenHouse.vue';
import ListingStatus from './overlay/ListingStatus.vue';

export default {
  name: 'ListingTop',
  components: {
    PhotoViewer,
    FavoriteListing,
    VirtualTour,
    OpenHouse,
    ListingStatus
  },
  props: [ 'listing', 'userFavorite', 'autoInitPhotoViewer' ],
  data(){

    // this.listing.status = 'New';
    // this.listing.openHouse = '11/20';

    return {

      placeholderImage: 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
      defaultImage: '/assets/media/no-image-available.jpg'

    }

  },

  computed: {
    price(){
      return this.listing.priceFormatted || this.formatPrice( this.listing.price );
    }
  },

  methods: {

    formatPrice: function( price ){
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      //remove trailing 00
      price = price.substr( 0, price.length - 2 );
      //format with $X,XXX,XXX and remove trailing .00
      return USDollar.format( price ).split( '.' )[ 0 ];
    },

    toggleFavoriteStatus( v ){
      this.$emit( 'toggleFavoriteStatus', v );
    },

  },

  //- watch: {
  //-
  //-   listing: {
  //-     handler(newListing) {
  //-       this.images = JSON.parse( newListing.images );
  //-       this.backgroundImage = (this.images[0] || this.defaultImage);
  //-     },
  //-     immediate: true,
  //-   },
  //- },

  mounted(){
    // console.log( this.listing );
    this.$refs.stockPhoto.style.opacity = 0;
    this.$refs.stockPhoto.style.pointerEvents = 'none';
  }

}

</script>

<style lang="scss">
.accent-bar { opacity: 1 }
.listing-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 33;
  user-select: none;
  pointer-events: none;
  * {
    user-select: none;
    pointer-events: all;
  }
}
.openhouse {
  opacity: .4;
}
.virtual-tour {
  opacity: .8;
  font-size: 2.618rem;
}
</style>