const DEBUG = 0;

export default {

  /**
   * @method averageCoords
   * averages the coordinates of all markers with bias towards center
   * @param {listing} coords 
   */

  averageCoords( coords ){

    //center on Richmond city as default
    var lat = this.mapCenter['lat'];
    var lng = this.mapCenter['lng'];

    var len = coords.length + 1;

    for( var c in coords ){
      //- console.log( lat, lng );
      lat += +coords[c]['lat']; // + ensures number, don't remove
      lng += +coords[c]['lng']; // + ensures number, don't remove
      if( DEBUG > 3 ) console.log( coords[c]['lat'], coords[c]['lng'] );
    }

    lat = lat / len;
    lng = lng / len;

    var ret = {
      lat: lat,
      lng: lng
    }

    if( DEBUG > 2 ) console.log( 'Centered Coords =', ret );

    if( !ret[lat] || !ret[lng] ) return false;

    return ret;

  },
  /**
   * @method clickSniffer
   * checks for clicks. if map is clicked and a marker is active, closes marker
   */

  clickSniffer(){
    const self = this;    
    // console.log( 'starting click sniffer' );
    self.event( 'click', function( e ){
      if( DEBUG > 2 ) console.log( 'Clicked #map (googleMap)' );
      if( DEBUG > 3 ) console.log( e );
      console.log( 'Active Marker', self.markerActive );
      if( self.markerActive ){
        self.markerActive.close();
      }
    });
  },

  /**
   * @method boundsChecker
   * checks when boundary changes
   */
  
  boundsChecker(){
    const e = this.event( 'bounds_changed', function() {
      // Handle the map viewport change
      if( DEBUG > 1 ) console.log('Map viewport changed');
      // You can perform additional actions here based on the viewport change
    });
  },

  async getBoundaries(){
    await this.ready;
    const b = await this.googleMap.getBounds();
    if( !b ) return this.listings.maxBounds;
    //console.log( 'bb', this.googleMap );
    const d = {
      north: b.getNorthEast().lat(),
      east: b.getNorthEast().lng(),
      south: b.getSouthWest().lat(),
      west: b.getSouthWest().lng(),
    };
    return d;
  },

  createMaxBoundsRectangle(){
    if( DEBUG > 2 ) console.log( 'marking max bounds', this.listings.maxBounds );
    // Create a rectangle
    const rectangle = new google.maps.Rectangle({
      bounds: this.listings.maxBounds,
      map: this.googleMap,
      strokeColor: 'blue', // Change color as needed
      strokeOpacity: 0.2,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0,
      icons: [{
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 4,
        },
        offset: '0',
        repeat: '100px', // Adjust the repeat value as needed
      }],
    });
    return rectangle;
  },

  zoomOut( change = 1 ){
    this.googleMap.setZoom( this.zoomFactor -= change );
  },

  zoomIn( change = 1 ){
    this.googleMap.setZoom( this.zoomFactor += change );
  },

  createRichmondMarker(){
    
    const svgString = `<?xml version="1.0" ?><svg class="feather feather-star" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/></svg>`;
    const parser = new DOMParser();
    const pinSvg = parser.parseFromString(
      svgString,
      "image/svg+xml",
    ).documentElement;

    const richmondMarker = new this.googleMarker({
      //position: { "lat" : 37.5407246, "lng" : -77.43604809999999 }, 
      position: this.mapCenter, 
      map: this.googleMap,
      content: pinSvg 
    })
  
    const infoWindow = new google.maps.InfoWindow();

    const self = this;

    richmondMarker.addListener( 'click', () => {
      infoWindow.setContent( '<p>Learn about your new home:</p><div style="padding-top: 10px"><a href="https://whyrichmondisawesome.com/?r=mls-star" target="_blank"><img src="https://whyrichmondisawesome.com/wp-content/uploads/logo-400x100.png" style="max-width: 100%" /></a></div>' );
      infoWindow.setPosition( self.mapCenter );
      infoWindow.open( self.googleMap );
    });

    return richmondMarker;

  }

}