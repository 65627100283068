<style>
.hide { display: none }
</style>

<template lang="pug">

.mls-filter(
  ref="filter"
)

  .mls-filter-toggle(
    @click="toggleVariables"
  )

    .mls-filter-name Property Types

    .mls-filter-type(:class="'mls-filter-type-' + filter.type" )

  .mls-filter-variables(:class="{ open: isFilterOpen }" ref="vars")

    //- handle Check boxes
    .mls-filter-checkbox(
      v-if="filter.type === 'checkbox'"
      v-for="option in filter.options"
    )
      .mls-filter-variable(@click='select')
        input(
          type='checkbox'
          :name="filter.name"
          :value="option"
          @change='change($event)'
          v-model="propertyTypes"
        )
        label(
          :for='filter.name'
        ) {{option}}
</template>

<script>

const DEBUG = 0;

export default {
  name: 'PropertyTypes',
  props: [ 'filter', 'open', 'state' ],
  data() {
    return {
      isFilterOpen: this.open,
      propertyTypes: this.filter.defaultOption
    }
  },
  methods: {

    change(event){
      if( DEBUG ) console.log( 'Change triggered' )
      this.$emit( 'propertyTypeChanged', this.propertyTypes );
      this.$emit( 'updateFilterStates', 'PropertyTypes', this.propertyTypes );
    },

    select(event){
      let p = event.target.parentElement;
      const i = p.querySelector('input');
      i.checked = !i.checked;
      const changeEvent = new Event( "change", { bubbles:false } );
      i.dispatchEvent(changeEvent);
    },

    toggleVariables(){
      var alreadyOpen = this.isFilterOpen;
      if( DEBUG ) console.log( 'alreadyOpen?', alreadyOpen );
      this.$emit( 'variablesToggled' );
      this.isFilterOpen = !this.isFilterOpen; // Toggle visibility
      if( alreadyOpen ) this.closeVariables();
    },

    openVariables(){
      if( DEBUG ) console.log( 'opening variables' );
      this.isFilterOpen = true;
    },

    closeVariables(){
      this.isFilterOpen = false;
    },

  },

  mounted(){
  }

}

</script>