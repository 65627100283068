<template lang="pug">
.mls-filter(
  ref="filter"
)

  .mls-filter-toggle(
    @click="toggleVariables"
  )

    .mls-filter-name Price

    .mls-filter-type(:class="'mls-filter-type-' + filter.type" )

  .mls-filter-variables(:class="{ open: isFilterOpen }" ref="vars")

    .mls-filter-price(
      v-if="filter.type === 'range'"      
      @change='change($event)'
    )

    .custom-range-slider
    
      .range-slider(id='price-range-slider')
        .range-slider-field
          label(for="price-amount") Price range:
          input(type="text" id="price-amount" readonly)      
        .range-slider-element
          .range-slider-slide(id="price-slider")
        input(name='price-min-value' hidden @change="changePriceMin")
        input(name='price-max-value' hidden @change="changePriceMax")

</template>

<script>

const DEBUG = 0; 

// let defaultMin = 0;
// let defaultMax = 0;

export default {
  name: 'Price',
  props: [ 'filter', 'open', 'state' ],
  data() {
    return {
      isFilterOpen: this.open
    }
  },
  methods: {

    changePriceMin(event){
      if( DEBUG ) console.log( 'Change triggered' )
      const priceMin = event.target.value;
      this.$emit( 'priceMinChanged', priceMin );
      this.$emit( 'updateFilterStates', 'priceMin', priceMin );
    },

    changePriceMax(event){
      if( DEBUG ) console.log( 'Change triggered' )
      const priceMax = event.target.value;
      this.$emit( 'priceMaxChanged', priceMax );
      this.$emit( 'updateFilterStates', 'priceMax', priceMax );
    },

    toggleVariables(){
      var alreadyOpen = this.isFilterOpen;
      if( DEBUG ) console.log( 'alreadyOpen?', alreadyOpen );
      this.$emit( 'variablesToggled' );
      this.isFilterOpen = !this.isFilterOpen; // Toggle visibility
      if( alreadyOpen ) this.closeVariables();
    },

    openVariables(){
      //console.log( 'opening variables' );
      this.isFilterOpen = true;
    },

    closeVariables(){
      this.isFilterOpen = false;
    },

  },

  mounted(){
    //this.initFilter();
    const p = this.filter.defaultOption;
    createSlider( 'price', p[0], p[1] );
  }

}

/*----------------------------------------------------------------------------*/

// only here because it was already coded like this

var $ = require('jquery');
var ui = require('jquery-ui');

$.extend( ui );

var USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  var formatPrice = function( price ){
    return USDollar.format( price ).split('.')[0];
  }

  var createSlider = function( name, min = 0, max = 0 ){
    
    var rID = '#' + name + '-slider';
    var aID = '#' + name + '-amount';

    var rawMin = 0;
    var rawMax = 0;

    var minInput = $( 'input[name="' + name + '-min-value"]' );
    var maxInput = $( 'input[name="' + name + '-max-value"]' );

    //console.log( minInput.length );

    var getPriceRange = function(){
      return formatPrice( rawMin )
        + " - "
        + formatPrice( rawMax )
      ;
    }

    var setMinMax = function( firstRun ){
      minInput.val( rawMin );
      maxInput.val( rawMax );
      // hacks for Vue
      if( firstRun ) return; //kill so button doesn't auto-green
      const changeEvent = new Event( "change", { bubbles:true } );
      //console.log(minInput);
      minInput[0].dispatchEvent( changeEvent );
      maxInput[0].dispatchEvent( changeEvent );
    }

    var sliderConfig = {
      range: true,
      min: 0,
      max: 1000000,
      step: 10000,
      values: [ min, max ],
      slide: function( event, ui ) {
        rawMin = ui.values[ 0 ];
        rawMax = ui.values[ 1 ];
        $( aID ).val( getPriceRange() );
        //add + if max, insinuating max amount or greater
        if( rawMax === sliderConfig.max )
          $( aID ).val( $( aID ).val() + '+' );
        setMinMax();
      }
    };

    $( rID ).slider( sliderConfig );
    rawMin = $( rID ).slider( "values", 0 );
    rawMax = $( rID ).slider( "values", 1 );
    $( aID ).val( getPriceRange() );
    setMinMax( true );
  }

  $(document).ready( () => {
    //createSlider( 'price' );
  })

</script>

<style lang="scss" scoped>

$arrow-size: 10px;
$arrow-color: $gg-green;
$arrow-color: red;

.arrow-down {
  width: 0;
  height: 0;
  border-left: $arrow-size solid transparent;
  border-right: $arrow-size solid transparent;
  border-top: $arrow-size solid $arrow-color;
}

@import url(https://code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css);
@import url(https://code.jquery.com/ui/1.13.2/jquery-ui.js);
@import url(https://jqueryui.com/resources/demos/style.css);

$ui-slider-handle-size: 10px;
$ui-slider-handle-padding: 10px;
$ui-slider-horizontal-height: 6px;

.ui-slider-orig {
  .ui-slider-handle {
    border-radius: 50%;
    width: $ui-slider-handle-size;
    height: $ui-slider-handle-size;
    padding: $ui-slider-handle-padding;
    margin-top: -( calc( $ui-slider-handle-padding / 2 ) + calc( $ui-slider-handle-size / 2 ) );
    margin-left: -( calc( $ui-slider-handle-padding ) + calc( $ui-slider-handle-size / 2 ) );
  }
}

.custom-range-slider {
  color: $gg-dark;
  position: relative;
}

#price-range-slider {
  padding: 1em;
  max-width: 224px;
  .range-slider-field {
    font-weight: 700;
  }
  label {
    display: inline-block;
    margin-bottom: 1em;
    display: none;
  }
  input {
    @extend %gg-font2;
    border: 0;
    color: $gg-blue;
    color: $gg-dark;
    font-size: 1.618rem;
    margin-bottom: 1em;
    margin-left: -.75em;
    max-width: 220px;
    background: transparent;
  }
  .range-slider-element {
    .ui-slider-horizontal {
      height: $ui-slider-horizontal-height;
    }
  }
  .range-slider-slide {
    background: $gg-gray;
    .ui-widget-header {
      background: $gg-green;
    }
    .ui-slider-handle {
      background-color: transparent !important;
      border-bottom-color: transparent !important;
      @extend .arrow-down;
      margin-top: -5px;
      margin-left: -10px; //weird but fixes it/matches lines
      outline: unset;
      &.ui-state-active {
        // background: $gg-blue;
        // border-color: $gg-lite !important;
      }
      &.ui-state-focus {
        // background: $gg-blue;
        // border-color: $gg-lite !important;
        color: $gg-lite !important;
      }
      &.ui-state-hover {
        // background: $gg-blue;
      }
    }
  }
}
</style>