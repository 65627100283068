<template lang="pug">
.openhouse(v-if='openHouse')
  div Open House {{openHouse}}
</template>

<script>

export default {
  name: 'OpenHouse',
  props: ['openHouse'],
}

</script>

<style lang="scss" scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
.openhouse {
  position: absolute;
  left: 0;
  bottom: 48px;
  margin: .4em;
  background: $gg-blue;
  color: $gg-lite;
  padding: .2em .4em;
  border-radius: .4em;
}
</style>