<template lang="pug">

.overlay-page(ref="overlay" @click="determineClick")
  .overlay-page-container(ref='container')
    //- (v-click-outside="console.log('clickedOutside')")
    //- .wrap(:click-outside="()=>{console.log('clickedOutside')}")
    .wrap()
      .top-block
        .left
          p.font-body 
            span.now-viewing Viewing 
            span.address
              span.street {{ listings.selected.street }} 
              span.rest 
                |(
                span.city {{ listings.selected.city }}, 
                span.state {{ listings.selected.state }}
                |)

        .right           
          button.close-button(@click="hideOverlay") Close

      hr

      p.listing-information

        span.listing-info.price
          //- span.icon
            img(src='~@/assets/media/icon-price.svg')
          span.text  Asking
          span.value {{ listings.selected.priceFormatted }}

        span.listing-info.beds
          span.icon
            img(src='~@/assets/media/icon-bed.svg')
          span.value {{ listings.selected.bedrooms }} 
          span.text  Bed 

        span.listing-info.baths
          span.icon
            img(src='~@/assets/media/icon-bath.svg')
          span.value {{ listings.selected.bathrooms }} 
          span.text  Bath 
        
        span.listing-info.footage
          span.icon
            img(src='~@/assets/media/icon-sqft.svg')
          span.value {{ listings.selected.footage }} 
          span.text 
            //-span(style='font-size: .8em') 
            span Ft
              sup 2        

      .bottom-portion
        .listing-area
          Listing(:listing="listings.selected" autoInitPhotoViewer="listings.selected.currentPhoto")
          //-PhotoViewer(:images="images" ref="photos")
        .iframe-area
          .loading-iframe(v-if="!iframeLoaded")
            p Loading listing info... 
          iframe(
            :src="listings.selected.url + '?hideAll=true'"
            class="idx-page"
            :style="'height: ' + iframeHeight + 'px'"
          )
        .floating-close-button(@click="hideOverlay")
          .floating-close-button-shell
            .floating-close-button-wrap
              .floating-close-button-content
                | Close Listing

</template>

<script>

const DEBUG = 2;

import Listing from '@/components/listings/listing/Listing.vue';
import PhotoViewer from '@/components/listings/listing/photoViewer/PhotoViewer.vue';
import { useAppStore } from '@/store/app';
import { useListingsStore } from '@/store/listings';

//import clickOutside from '@/composables/clickOutside';

export default {
  name: 'OverlayPage',
  // prop: [ 'listing' ],
  components: {
    Listing,
    PhotoViewer
  },
  directives: {
   // 'click-outside': ClickOutsideDirective,
  },
  data(){
    return {
      listings: useListingsStore(),
      images: [],
      url: '',
      iframeHeight: 100,
      app: useAppStore(),
      clickHandler: null,
      iframeLoaded: false
      //activeListing: null
    }
  },
  methods: {
    closeOnEsc( e ){      
      if( e.key === 'Escape' ) this.app.overlay = false;     
    },
    hideOverlay(){
      this.app.overlay = false;
    },
    handleClickOutside( container ){
      return ( e ) => {
        if( container.contains( e.target ) ){
          // console.log('Clicked inside the container.');
        } else {
          // console.log('Clicked outside the container.');
          this.app.overlay = false;
        }
      }
    },
    determineClick( e ){
      console.log( this, e  );
      // element.value && !element.value.contains( event.target ) 
    }
  },
  async created(){
    // temp promise bc race would never occur in production usage
    //await new Promise((resolve) => setTimeout( resolve, 1000 ));

    //this.activeListing = this.listings.selected = this.listings.all[4];

    // const qs = '?hideAll=true';
    // this.url = this.listings.selected.url + qs;

    
    //this.images = this.listings.selected.images;
    // console.log( this.images );
    //console.log( this.$refs.photos );
    


  },
  async mounted(){

    // const co = clickOutside( () => this.app.overlay = false );
    // co.value = this.$refs.container;    

    //this.trackClickOutside( this.$refs.parent, this.$refs.container );
        
    const iframe = document.querySelector('.idx-page');

    if( !iframe ) return;
    
    window.addEventListener( 'message', ( m ) => {        
      if( m.origin == 'https://www.idxhome.com' ){
        if( DEBUG > 3 ) console.log( 'Message received:', m.data );
        const data = JSON.parse( m.data );
        if( DEBUG > 1 ) console.log( 'iframe sent message: (data)', m.data );
        // const buffer = 24;
        const buffer = 44; // 44px is the height of CVRMLS logo in case it takes it's sweet time
        this.iframeHeight = data.bodyHeight + buffer;
      }
    })

    const self = this;
    
    // iframe.onload = function () {
    iframe.addEventListener( 'load', function() {

      // setTimeout(() => {

      self.iframeLoaded = true;

      iframe.style.opacity = 1;

      // }, 10000000);

    });

    document.addEventListener( 'keydown', this.closeOnEsc );
    this.clickHandler = this.handleClickOutside( this.$refs.container );
    //document.addEventListener( 'click', this.clickHandler );

    //this.$refs.photos.init.bind(this.$refs.photos.init)();
    
  },

  unmounted(){
    document.removeEventListener( 'keydown', this.closeOnEsc );
    //document.removeEventListener( 'click', this.clickHandler );
  }

}

</script>

<style lang="scss">


.mls-app .overlay-page {

  position: absolute;
  background: transparentize( $gg-gray-dark, .12 );
  z-index: 100;
  width: 100%;
  height: 100%;  
  pointer-events: all;

  small, sub, sup { font-size: .8em }
  sub             { vertical-align: sub }
  sup             { vertical-align: super }


  &-container {
    max-width: 61.8vw;
    max-width: 1186px;
    margin: auto;
    height: 100%;
  }

  .wrap {

    background-color: $gg-lite;
    margin: 1em;
    padding: 1em;
    // width: 100%;
    height: 100%;
    // max-width: calc( 100vw - 2em );
    max-height: calc( 100vh - 2em );
    max-height: calc( 100dvh - 2em );
    overflow-x: hidden;
    overflow-y: hidden;
    overflow-y: scroll;

    .top-block {
      display: flex;
      // grid-template-columns: .618fr .382fr;
      padding: .382em;
      padding-top: .2em;
      .now-viewing {
        color: $gg-gray-dark;
        font-style: italic;
        margin-right: .618em;
        vertical-align: text-top;
        // display: none;
        // opacity: .8;
      }
      .left {
        flex-grow: 1;

      }
      .right {

      }
    }

    .xgrid {
      display: grid;
      grid-template-rows: .618fr .382fr;
      height: 100%;
      overflow-y: scroll;
    }
    
    .bottom-portion {
      // display: flex;
    }

    .listing-area {
      position: relative;
      padding: .382em;
      // flex: 0 2;
      // height: 61.8vh;
      // max-height: ( 1200 * .618 ) + 'px';
      //background: red;
      //overflow: hidden;
      .listing {
        width: auto !important;
        padding: 0 !important;
        &, .photo-wrap .photo {
          max-width: none;
        }
        .accent-bar, .info { display: none !important; }
      }
    }

    .iframe-area {
      // flex: 1 2;
      height: 100%; 
    }

    .loading-iframe {
      
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100%;

      p {
        font-size: 18px;
        text-align: center;
        padding: 1em;     
        margin-top: 1.618em;  
      }
    }

    iframe {
      width: 100%;
      height: 100%;      
      opacity: 0;
      transition: opacity .618s linear;
    }

  }

  hr {
    margin: .618em;
  }



  .listing-information {
    display: flex;
    margin: 1em 0 .618em;    
    padding: 0 .618em;
    span {
      display: inline-block;
      height: 24px;
      text-align: right;
    }
    .listing-info {
      margin-right: 10px
    }
    .icon {
      width: 16px;                
      margin-right: 6px;
      opacity: .8;
      align-self: center;
      img { vertical-align: initial; }
    }
    .value {
      color: $gg-blue;
      font-size: 1.3em;
      margin-right: 6px;
      // font-weight: 500;
    }
    .text {
      // color: $gg-gray-dark;
      opacity: .8;
      font-size: .8em;
      // display: none;
    }
    .price {
      // margin-left: 1.618em;
      margin-right: 1.618em;
      flex-grow: 1;
      // text-align: right;
      text-align: left;
      .text {
        margin-right: .618em;
        vertical-align: text-top;
        margin-top: 4px;
      }
    }
  }

  .address {
    .street {
      color: $gg-blue;
      font-size: 1.618em;
      margin-right: 10px;
    }
    .rest {
      opacity: .8;
      font-size: .8em;
      // font-style: italic;
    }
  }

  .close-button {
    padding: .382em;
  }

  .bottom-portion {
    position: relative;
  }
  // @TODO: make it work in container not fixed...
  .floating-close-button {
    $primary-color: $gg-green;
    $rounding-value: 12px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    cursor: pointer;
    &-shell {
      position: relative;
      background-color: $gg-lite;
      margin: 1em;
      padding: .384em;
      // margin-top: 0;
      &::before {
        $gradient-height: 1.618em;
        position: absolute;
        width: 100%;
        height: $gradient-height;
        top: -$gradient-height;
        left: 0;
        content: '';
        background: linear-gradient( transparent, $gg-lite );
        // background: linear-gradient( blue, red );
        z-index: 999;
      }
    }
    &-wrap {
      font-size: 1.618em;      
      // background: $gg-green;
      color: $gg-lite;
      text-align: center;
      // margin: auto;
      // margin: .618em;
      border: 4px double $primary-color;
      border-radius: $rounding-value;      
    }
    &-content {
      background: $primary-color;
      padding: 1.618em;
      padding: 1em;
      border-radius: $rounding-value / 2;
    }
  }

}

.mls-app.screen-tiny .overlay-page {
  .listing-information {   
    span { height: 16px; }   
    .icon { width: 12px; }
  }
}
</style>