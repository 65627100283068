<template lang="pug">

.filters
  Bedrooms(
    :filter="filters.bedrooms"
    ref="bedrooms"
    @updateFilterStates="updateFilterStates"
    @variablesToggled="closeAllVariables"
  )

  Bathrooms(
    :filter="filters.bathrooms"
    ref="bathrooms"
    @updateFilterStates="updateFilterStates"
    @variablesToggled="closeAllVariables"
  )

  Price(
    :filter="filters.price"
    ref="price"
    @updateFilterStates="updateFilterStates"
    @variablesToggled="closeAllVariables"
  )

  PropertyTypes(
    :filter="filters.propertyTypes"
    ref="propertyTypes"
    open='true'
    @updateFilterStates="updateFilterStates"
    @variablesToggled="closeAllVariables"
  )

</template>

<script>
const DEBUG = 0;

import Filter from './Filter.vue';
import filterData from './filterData.json';

import Bedrooms from './filter/Bedrooms.vue';
import Bathrooms from './filter/Bathrooms.vue';
import Price from './filter/Price.vue';
import PropertyTypes from './filter/PropertyTypes.vue';

import { useListingsStore } from '@/store/listings';

export default {
  name: 'Filters',
  components: {
    Filter,
    Bedrooms,
    Bathrooms,
    Price,
    PropertyTypes
  },
  data() {
    return {
      listings: useListingsStore(),
      filters: filterData,
      filterStates: {
        bedrooms: 0,
        bathrooms: 0,
        footage: 0,
        priceMin: 0,
        priceMax: 0,
        propertyTypes: []
      },
      isFilterOpen: false,
      changesMade: false,
      customComponents: []
    }
  },
  methods: {

    initFilters(){
      this.customComponents.push( this.$refs.bedrooms );
      this.customComponents.push( this.$refs.bathrooms );
      this.customComponents.push( this.$refs.price );
      this.customComponents.push( this.$refs.propertyTypes );
    },

    closeAllVariables() {
      if( DEBUG ) console.log( 'closing all variables' );
      for( const filter in this.customComponents ){
        this.customComponents[filter].closeVariables()
      }
    },

    filterValueChange(){
      //if( !this.changesMade ){
        //this.changesMade = true;
      this.$emit( 'filterValuesChanged', this.filterStates );
      //}
    },

    updateFilterStates( term, value ){
      if( DEBUG ) console.log('updating filter states', term, value)
      this.filterStates[term] = value;
      this.filterValueChange();
    }

  },

  mounted() {
    // Initialize your filters here
    this.initFilters();
  },
  
}


</script>

<style lang="scss">
</style>
