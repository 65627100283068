<template lang="pug">
.favorite-icon(
  @click="toggleFavorite"
)
  i(
    :class="favoriteClass"
  )
</template>

<script>

export default {
  name: 'FavoriteListing',
  props: [ 'favorite' ],
  data(){
    return {
      favoriteStatus: this.favorite
    }
  },
  methods: {
    toggleFavorite(){
      //console.log('toggling favorite');
      //this.$refs.heart.classList.toggle('favorite');
      //this.favoriteStatus = !this.favoriteStatus;
      //console.log( 'toggle clicked' );
      this.$emit( 'toggledFavorite' )
    }
  },
  computed: {
    favoriteClass(){
      const base = 'fa fa-heart ';
      return base + ( this.favorite ? 'favorite' : '' );
    }
  },
  mounted(){
    // console.log( 'favorite listing?', this.favoriteStatus );    
  }
}

</script>

<style lang="scss" scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
.favorite-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 7px 9px;
}

$icon-size: 32px;
$icon-shadow-color: transparentize( $gg-green, .2 );
$icon-shadow-size: 2px;
$icon-shadow-size-hover: 5px;
$favorited-icon-shadow-color: transparentize( $gg-gray-lite, .2 );

i {
  color: white;
  font-size: $icon-size !important;
 // -webkit-text-fill-color: transparent;
//-webkit-text-stroke : black; // can be any color
  text-shadow: 
    -1px -1px $icon-shadow-size $icon-shadow-color,
    1px 1px $icon-shadow-size $icon-shadow-color,
    -1px 1px $icon-shadow-size $icon-shadow-color,
    1px -1px $icon-shadow-size $icon-shadow-color,    
  ;
  cursor: pointer;
  &.favorite {
    color: $gg-green;
    text-shadow: none;
    text-shadow: 
      1px -1px $icon-shadow-size $favorited-icon-shadow-color,
      1px 1px $icon-shadow-size $favorited-icon-shadow-color,
      -1px 1px $icon-shadow-size $favorited-icon-shadow-color,
      1px -1px $icon-shadow-size $favorited-icon-shadow-color,    
    ;
  }
  
  &:hover, &.favorite:hover {
    text-shadow: 
      -1px -1px $icon-shadow-size-hover $icon-shadow-color,
      1px 1px $icon-shadow-size-hover $icon-shadow-color,
      -1px 1px $icon-shadow-size-hover $icon-shadow-color,
      1px -1px $icon-shadow-size-hover $icon-shadow-color,    
    ;
  }
}
</style>