<template lang="pug">

template(v-if='listings.loaded && listings.total')

  .collapser
    .bubble(@click='app.toggleListings')
      .text
        .character &lt;
        .label
          .show-listings Show Listings
          .hide-listings Hide Listings

  .filters-at-top
    ListingsFiltersTop

  .mls-listings-sorter(ref='sorter' v-if="app.layout == 'vertical'")
    Sorter

  .mls-listings-wrap(
    ref='listingsWrap'
  )            
    Listings 
    .listings-status.note.center
      template(v-if="listings.active.length > 0")
        p(v-if="listings.active.length == listings.all.length") End of listings
        p(v-else) Zoom out for more
      template(v-else)
        .default-message-wrap
          p.default-message {{ listings.status }} 
          img(src="@/assets/media/arrow-down-right.svg")
template(v-else)
  .listings-loading
    .default-message-wrap
      p.default-message {{ listings.status || 'Awaiting filters...' }}    

//-.listings-pagination(ref='pagination')
  Pagination(
    @changePage='changePage'
  )

</template>

<script>

const DEBUG = 0;

import ListingsFiltersTop from '@/components/listings/ListingsFiltersTop.vue'
import Sorter from '@/components/listings/Sorter.vue'
import Listings from '@/components/listings/Listings.vue'
import Pagination from '@/components/listings/Pagination.vue'

import { useAppStore } from '@/store/app'; 
import { useListingsStore } from '@/store/listings'; 
import { useMapStore } from '@/store/map'; 

export default {
  name: 'ListingsMaster',
  components: {
    Sorter,
    Listings,
    Pagination,
    ListingsFiltersTop,
  },
  data(){
    return {
      app: useAppStore(),
      listings: useListingsStore(),
      //observer: new ListingsObserver(),
      map: useMapStore(),
    }
  }
}

</script>


<style lang="scss" scoped>

.listings-loading {
  height: 100%;
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xlistings {
  position: relative;
  min-height: 320px;
}

.xdefault-message-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xdefault-message {
    padding: 1.618em 1em;
  }
}

</style>