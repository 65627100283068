<template lang="pug">

.listings(
  @touchstart="listings.touchStart"
  @touchmove="listings.touchMove"
  @mouseenter="listings.allowHorizontalScroll"
)
  template(v-if='listings.loaded')
    Listing(
      v-for="listing in listings.active" 
      :listing="listing" 
      autoInitPhotoViewer="0"
      :key="listing.mls"
    )
    .listings-status-inset.note.center 
      p(v-if="listings.active.length == listings.all.length") End of listings
      p(v-else) Zoom out for more   
    
</template>

<script>

const DEBUG = 0;

import Listing from './listing/Listing.vue'

import { useListingsStore } from '@/store/listings';

export default {
  name: 'Listings',
  components: {
    Listing
  },
  data(){
    return {
      listings: useListingsStore(),
      observer: null
  
    }
  },  
  methods: {
    
  },
  async mounted(){

    await this.listings.init();
    
    if( DEBUG > 1 ){
      console.log( this.listings.active.length, 'active listings' );
      console.log( this.listings.all.length, 'total listings' );
    }   

  }
}

//let defaultImage = "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg";

</script>
