var _houses = [
{
  "images": [
    "https://api-prod.corelogic.com/trestle/Media/CVR/Property/PHOTO-jpeg/1022350954/1/Mzc4LzQxNDIvMTk/MTkvOTg2OS8xNjY2NjMzMjM1/OHgsp6UvPVVI8PKFibpGeoKBPDiCteMDvQvHt_O99eI"
  ],
  "price": "345000000",
  "address": "113 Libbie Avenue Richmond, VA 23226",
  "street": "113 Libbie Avenue",
  "url": "https://www.idxhome.com/homes/123363/31/113-LIBBIE-AVENUE-RICHMOND-VA-23226/2224450",
  "virtualTour": 1,
  "bedrooms": "5",
  "bathrooms": "7",
  "footage": "4957",
  "mls": "#2224450"
},
{
  "images": [
    "https://api-prod.corelogic.com/trestle/Media/CVR/Property/PHOTO-jpeg/1031816772/1/Mzc4LzQxNDIvMTk/MTkvOTg2OS8xNjcxMTI5NjQx/L5ysJW5xBjRAkONlYDs4BTj_aLyPhD-lllXgtnTvZS0"
  ],
  "price": "157500000",
  "address": "102 Lockgreen Place Richmond, VA 23226",
  "street": "102 Lockgreen Place",
  "url": "https://www.idxhome.com/homes/123363/31/102-LOCKGREEN-PLACE-RICHMOND-VA-23226/2231946",
  "virtualTour": 0,
  "bedrooms": "4",
  "bathrooms": "4",
  "footage": "4125",
  "mls": "#2231946"
},
{
  "images": [
    "https://api-prod.corelogic.com/trestle/Media/CVR/Property/PHOTO-jpeg/1021579045/1/Mzc4LzQxNDIvMTk/MTkvOTg2OS8xNjY0Mzk4MDEz/k5bLv6REVgQmCjKogSI_63vyrvHfiT001Lwnmo_8B-4"
  ],
  "price": "122900000",
  "address": "7609 Hampshire Road Henrico, VA 23229",
  "street": "7609 Hampshire Road",
  "url": "https://www.idxhome.com/homes/123363/31/7609-HAMPSHIRE-ROAD-HENRICO-VA-23229/2223286",
  "virtualTour": 0,
  "bedrooms": "3",
  "bathrooms": "5",
  "footage": "2738",
  "mls": "#2223286"
},
{
  "images": [
    "https://api-prod.corelogic.com/trestle/Media/CVR/Property/PHOTO-jpeg/1031773676/1/Mzc4LzQxNDIvMTk/MTkvOTg2OS8xNjcxMDU0MzAx/aFfKaL-rfldRIJDHgU9ZnJVcV_62CSe6loTqusuB6yo"
  ],
  "price": "115000000",
  "address": "10 Country Squire Lane Henrico, VA 23229",
  "street": "10 Country Squire Lane",
  "url": "https://www.idxhome.com/homes/123363/31/10-COUNTRY-SQUIRE-LANE-HENRICO-VA-23229/2232266",
  "virtualTour": 1,
  "bedrooms": "3",
  "bathrooms": "4",
  "footage": "5104",
  "mls": "#2232266"
}
]

//test houses
var house1 = { "lat": 37.5540593, "lng": -77.5025716 };
var house2 = { "lat": 37.5658423, "lng": -77.55356789999999 };
var house3 = { "lat": 37.565854, "lng": -77.49522379999999 };
var house4 = { "lat": 37.5729738, "lng": -77.5481939 };

//temp
house1.houseData = _houses[0];
house2.houseData = _houses[1];
house3.houseData = _houses[2];
house4.houseData = _houses[3];

let mapTestData = {
  houses: [ house1, house2, house3, house4 ]
}

export default mapTestData;