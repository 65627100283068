<template lang="pug">

.top-bar-container 
  .left 
    .client-logo
      a(href='/').icon-home
        img(src='https://www.alexsellsrichmond.com/files/media/png/glaser-group-logo-48px.png')

  .middle
    p(v-if="app.listingsInfo") {{ app.listingsInfo }}
    template(v-else)
      p(v-if="app.showDevBits")
        | {{ app.viewSize }} | {{ app.layout }} | {{ app.overlapListings ? 'yes' : 'no' }}

  .right 
    .account-area
      .user-icon.icon(@click='app.showAdmin()')
        .icon-wrap
          img(src='~@/assets/media/icon-account.svg')

</template>


<script setup>

import { useAppStore } from '@/store/app'

const app = useAppStore()

</script>