<template lang="pug">

// #map will be populated by JS
#map

  .map-loading(v-if="!mapLoaded")
    p Awaiting listings...

</template>


<script>
const DEBUG = 0;

// import map from './map.js'
import mapTestData from './mapTestData.js'

import { useMapStore } from '@/store/map';
import { useListingsStore } from '@/store/listings';

export default {

  name: 'Map',

  data(){
    return {
      map: useMapStore(),
      testData: mapTestData,
      mapLoaded: false,
      // googleMaps: useGoogleMapsStore(),
      listings: useListingsStore(),
    }
  },

  async mounted(){

    await this.map.getMap();

    if( DEBUG > 2 ) console.log( 'Map is waiting for listings.ready', this.listings.ready );

    await this.listings.ready;

    if( DEBUG > 2 ) console.log( 'map says listings ready' );

    // this.map.scrollToListing = this.scrollToListing;

    this.map.houses = this.listings.all; //@NOTE: must set houses before init!!

    this.map.init();

    this.mapLoaded = true; // clear Awaiting Listings... message

    // @DEVNOTE: no need, listings.init calls changeSortBy which calls filterByBounds
    // await self.listings.filterByBounds( self.map.getBoundaries() );

    // this.map.update(); // @DEVNOTE: not needed, map will idle on init 
    
    const self = this;

    this.map.event( 'idle', async function() {
      if( DEBUG > 1 ) console.log( 'Map idle - getting boundaries' ); 
      const b = await self.map.getBoundaries();     
      await self.listings.filterByBounds( b );
      self.map.update();
      // console.log( self.listings.active );
    });

    // console.log( '!!!!!!!!!Creating map clusters' );

    // // Apply marker clustering
    // var markerCluster = new MarkerClusterer( this.map.googleMap, this.map.markers, {
    //     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    // });

  },

}

</script>