<style lang="scss">

.filter-icon {
  width: 24px;
  opacity: .2;
}

.divider {
  margin-right: .2em;
}

.filter-shortcuts {
  display: flex;
}

.filter-shortcut {
  display: flex;
  margin-right: .6em;
  .icon {
    width: 16px;
    margin-right: .24em;
    max-height: 12px;
  }
  .text {
    color: $gg-blue;
    font-size: .8em;
  }
}

</style>

<template lang="pug">

mixin filterProp( icon, value )
  .filter-shortcut
    .icon
      img(src='@/assets/media/' + icon)
    .text {{#{value}}}

.filter-shortcuts
  .filter-icon
    img(src='~@/assets/media/icon-filters.svg')
  span.divider =
  +filterProp( 'bed-icon.svg', 'bedValue' )
  +filterProp( 'bath.png', 'bathValue' )
  +filterProp( 'money.png', 'priceValue' )
  +filterProp( 'money.png', 'propertyType' )


</template>

<script>

import FilterTop from './FilterTop.vue';

export default {
  name: 'FiltersTop',
  components: {
    FilterTop
  },
  data() {
    return {
      bedMin: 2,
      bedMax: 4,
      bathMin: 2,
      bathMax: 0,
      priceMin: 25000000,
      priceMax: 0,
    }
  },
  methods: {
    minMax( prop, unit='' ){
      if( !this[ prop + 'Max' ] || this[ prop + 'Max' ] == this[ prop + 'Min' ] ) return this[ prop + 'Min' ] + unit + '+';
      return `${this[ prop + 'Min' ]} - ${this[ prop + 'Max' ]}${unit}`;
    }
  },
  computed: {
    bedValue(){
      return this.minMax( 'bed' );
    },
    bathValue(){
      return this.minMax( 'bath' );
    },
    priceValue(){
      //- return '250K+';
      return '250-450K';
    },
    propertyType(){
      return 'House';
    }
  }
}


</script>

<style lang="scss">
</style>
