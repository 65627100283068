const DEBUG = 0;

import { defineStore } from 'pinia'

import createHousePopup from './listingPopup';

import { useGoogleStore } from '@/store/google';
import { useListingsStore } from '@/store/listings';

import helpers from './helpers';
import markers from './markers';

export const useMapStore = defineStore('map', {

  state: () => {

    return {

      // the default center of the map
      mapCenter: { "lat" : 37.5407246, "lng" : -77.43604809999999 },
      
      // default zoom level of map
      zoomFactor: 12,

      // array of houseData objects (listings.all)
      houses: [],

      // holds custom "marker" objects with Google Marker inside
      markers: [],

      // number of markers available
      markerCount: 0,

      // holds active marker or is null (if none are active)
      markerActive: null,

      // local google store
      google: useGoogleStore(),

      // holds the Google Maps master object
      googleMaps: null,

      // holds the instantiated Google Map
      googleMap: null,  

      // holds AdvancedMarkerElement (if used)
      googleMarker: null,
      
      _ready: {
        resolve: () => {},
        reject: () => {}
      },

      // ready: null, // becomes promise. hidden so IDE doesn't flag

      //ready: new Promise((resolve, reject) => {}), // risky but IDE would not shut up

      listings: useListingsStore(), 

      useAdvancedMarkers: false, // when true uses raster map with mapId and AdvancedMarkerElement

      useVectorMap: false, // when true upgrades map to WebGL-driven map

      markMaxBounds: false // draws a rectangle around max bounds when true

    }

  },

  actions: {
    
    ...helpers,
    ...markers,

    createHousePopup,

    makeReady(){
      const promise = new Promise((resolve, reject) => {
        this._ready.resolve = resolve;
        this._ready.reject = reject;        
      })
      this.ready = promise;
    },  

    /**
     * @method getMap
     * getMap loads (or returns loaded) googleMap.
     * @DEVNOTE: add any library imports here
     */
    
    async getMap(){

      this.makeReady(); 

      try {
        if( this.googleMaps ) {
          if( DEBUG > 3 ) console.log( 'found map, returning' );
          return this.googleMaps;
        }
        else {
          const google = await this.google.load();
          const { Map } = await google.maps.importLibrary("maps");
          // additional libraries
          if( this.useAdvancedMarkers ){
            if( DEBUG > 1 ) console.log( 'Attaching AdvancedMarker' );
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            this.googleMarker = AdvancedMarkerElement;            
          }
          return this.googleMaps = Map; // this is Google Maps object, not instantiated map element
        }
      } catch( e ){ 
        this._ready.reject( e );
        return e; 
      }
      
    },

    /**
     * @method event
     * shortcut method for google.maps.event.addListener
     */

    event( e, cb ){
      const m = this.googleMap
      // console.log( m, e, typeof cb );
      return google.maps.event.addListener( m, e, cb );
    },
    

    /**
      * @method update
      * redraws the map with fresh markers based on map.markers
      */

    update(){

      if( DEBUG ) console.log( 'updating map' );

      this.houses = this.listings.all; //should be ALL so markers get created on init

      if( this.markMaxBounds ) this.event( 'idle', this.createMaxBoundsRectangle );    

      this.processMarkers();

      //zoom out if 0 active but listings exist with current filters
      if( !this.listings.active.length && this.listings.all.length ){
        this.zoomOut();
        setTimeout( this.update, 600 );
      }

    },

    /**
     * @method initializeMap
     * initializes the map, sets center based on avg. coords, sets map.googleMap property
     */

    initializeMap( averageCoords ){

      if( DEBUG > 4 ) console.log( window.google );

      // basic map config
      const mapConfig = {
        zoom: this.zoomFactor,
        center: averageCoords || this.mapCenter,
        minZoom: 8,
        maxZoom: 19,
        restriction: {
          latLngBounds: {
            "north": 40, // higher number = more north
            "east": -74, // lower number = more east (disregard the negative)
            "south": 35, // lower number = more south 
            "west": -80 // higher number = more west (disregard the negative)
          },
        },
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: "greedy"
      };

      // advanced map config options      
      // if( this.useAdvancedMarkers ) mapConfig.mapId = 'ID907b0f98e3090812'; //previous ID was bullshit? has ID in front...
      if( this.useAdvancedMarkers ) mapConfig.mapId = '907b0f98e3090812';
      if( this.useVectorMap ) mapConfig.mapId = 'b1921d329014eee8';

      //initialize/create map
      var _map = new google.maps.Map(
        document.getElementById("map"),
        mapConfig
      );

      this.googleMap = _map;

      if( this.useAdvancedMarkers ) this.createRichmondMarker();

      this._ready.resolve( this.googleMaps );

      if( DEBUG > 1 ) console.log( 'Map (store) is ready.' );

    },

    /**
     * @method init
     * the true init function. gathers avg. coords, initializes map, performs other necessary functions
     */

    init(){

      if( DEBUG ) console.log( `initializing map with ${this.houses.length} houses` );
      //console.log( 'houses::', map.houses )

      var avg = this.averageCoords( this.houses );

      this.mapAverage = avg;

      this.initializeMap( avg );

      this.clickSniffer();

      this.boundsChecker();

      return this;

    }



  }

})