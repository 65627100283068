const DEBUG = 0;

import axios from 'axios'

// import testListings from './../test/mls-sample-listing.json';
// import testListings from '@/test/mls-shallow-pull-23-12-14.json';
import testListings from '@/test/mls-4-houses-geocoded.json';

export default {

  async fetch( filterSettings, listingsLimit = 0 ){

    if( DEBUG ) console.log( 'fetching listings' );

    if( this._firstPromise ){
      this._ready.resolve();
      this._firstPromise = false;
    }
    
    this.makeReady();

    if( this.useTestListings ){
      console.warn( 'USING TEST LISTINGS' );
      return new Promise(( res ) => {
        const listings = testListings.slice( 0, this.testListingsLimit || 1 );
        for( const listing of listings ) delete listing.metadata;
        this.update( listings );
        res( listings );
      })
    }

    const useMaxBounds = true; 
    // only returns listings that are within maxBounds
    if( useMaxBounds ){
      const b = this.maxBounds;
      filterSettings.lat = [ b.south, b.north ], // S < N
      filterSettings.lng = [ b.west, b.east ] // W < E
      // console.log( filterSettings );
    }
    
    let config = {
      method: 'get',
      // url: 'https://www.alexsellsrichmond.com/content/special/mls/db.php',
      url: 'https://mls.alexsellsrichmond.com/db/db.php',
      params: {
        action: 'fetch',
        filters: filterSettings,
        limit: listingsLimit
      },
    }
    try {
      if( DEBUG > 3 ) console.log( 'Calling axios' );
      const response = await axios( config );
      // await new Promise(resolve => setTimeout(resolve, 80000)); // for UI Tests only
      if( DEBUG > 3 ) console.log( 'Axios done.' );
      // console.log( 'response', response );
      if( DEBUG > 2 ) console.log( 'response.data', response.data );
      //const arrow = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve"><g><path d="M77.144,22.54c-7.43-7.43-17.304-11.521-27.802-11.521c-10.499,0-20.373,4.092-27.802,11.521   c-7.429,7.43-11.521,17.304-11.521,27.803c0,10.5,4.092,20.373,11.521,27.802c7.43,7.43,17.303,11.521,27.802,11.521   c10.499,0,20.373-4.092,27.802-11.521C92.474,62.814,92.474,37.871,77.144,22.54z M74.294,75.295   c-6.669,6.669-15.53,10.342-24.953,10.342c-9.423,0-18.284-3.673-24.953-10.342c-6.668-6.668-10.341-15.529-10.341-24.953   c0-9.423,3.672-18.285,10.341-24.953c6.668-6.669,15.53-10.341,24.953-10.341c9.422,0,18.284,3.672,24.953,10.341   C88.053,39.148,88.053,61.536,74.294,75.295z"/><polygon points="61.358,59.51 36.734,34.885 33.885,37.735 58.509,62.359 36.624,62.359 36.624,66.389 65.388,66.389    65.388,37.625 61.358,37.625  "/></g></svg>`;
      if( !Array.isArray( response.data ) || !response.data.length ) this.status = 'No matching listings found (adjust filters).';
      this.update( response.data );
    } catch( error ){
      if( DEBUG > 1 ) console.error( 'Error fetching listings: ' + error );
      this.status = 'An error occured.';
      this._ready.reject( error );
    }
  },

  async fetchByMlsIds( mlsIds, listingsLimit = 10 ){
    if( DEBUG ) console.log( 'fetching by MLS IDs' );
    if( typeof mlsIds == 'number' ) mlsIds = [ mlsIds ];
    if( !(mlsIds instanceof Array) ) throw 'fetchByMlsIds requires INT or Array';
    let config = {
      method: 'get',
      url: 'https://www.alexsellsrichmond.com/content/special/mls/db.php',
      params: {
        action: 'fetchByMlsIds',
        mlsIds: mlsIds,
        limit: listingsLimit
      },
    }
    try {
      const response = await axios( config );
      // console.log( 'response', response );
      if( DEBUG > 2 ) console.log( 'response.data', response.data );
      // @DEVNOTE: fix this (remove if)
      if( !response.data.length ) this.status = 'No matching listings found (adjust filters).';
      else {
        return response.data; // no overwriting real listings
      }
    } catch( error ){
      console.error( 'Error fetching listings: ' + error );
      // this.status = 'An error occured.';
    }
  },

  async fetchTotalListingCount(){
    let config = {
      method: 'get',
      // url: 'https://www.alexsellsrichmond.com/content/special/mls/db.php',
      url: 'https://mls.alexsellsrichmond.com/db/db.php',
      params: {
        action: 'fetchTotalListingsCount'
      },
    }
    try {
      const response = await axios( config );
      // console.log( 'response', response );
      if( DEBUG > 2 ) console.log( 'response.data (Total Listings):', response.data );
      this.available = +response.data;
    } catch( error ){
      if( DEBUG > 1 ) console.error( 'Error fetching total listing count: ' + error );
      // this.status = 'An error occured.';
    }
  },

}