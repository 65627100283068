const DEBUG = 0;

export default {
  
  /**
   * @method createCustomMarker
    * creates a unique "custom marker" object and registers it to map.markers
    * @param mlsNumber {number} unique MLS number
    * @returns customMarkerObject {object}
    */

  createCustomMarker( mlsNumber ){    
    return this.markers[ mlsNumber ] = { id: this.markerCount++ };
  },

  /**
   * @method createMarker
   * creates a google marker using house/POI data
   * @param customMarker {object} ferried here then returned
   * @returns customMarker {object}
   */

  createMarker( customMarker ){

    if( DEBUG > 3 ) console.log( 'Creating google marker for custom marker', customMarker.id );

    // ensure proper LatLng object for Google Marker
    const coords = { lat: +customMarker.data.lat, lng: +customMarker.data.lng };

    // determine which marker class to use
    const m = this.useAdvancedMarkers ? this.googleMarker : google.maps.Marker;

    // boilerplate markerData consistent across both types
    const markerData = {
      position: coords,
      map: this.googleMap,
      title: `${customMarker.data.address}`,
    }

    // create actual Google Marker
    const marker = new m( markerData );

    // attach marker and markerData
    customMarker.googleMarker = marker;
    customMarker.googleMarkerData = markerData;

    // return custom marker object
    return customMarker;

  },

  /**
   * @method createMarkerPopup
   * determines how the popup modal will be
   * @param marker {CustomMarker}
   */

  createMarkerPopup( marker ){

    var demoContent = '<div>Info Window ' + marker.id + '</div>';
    var houseContent = this.createHousePopup( marker.data );
    // var houseContent = 'House Content (Test)';

    var infoWindow = new google.maps.InfoWindow({
      content: houseContent,
      // areaLabel: 'blank label'
      areaLabel: 'Listing Data'
    });

    const self = this;
    
    const gMark = marker.googleMarker;

    gMark.addListener( 'click', function( e ){
      // console.log( self );
      if( DEBUG > 2 ) console.log( 'Marker', marker.id, 'clicked', e.latLng.lat(), e.latLng.lng(), e );
      if( self.markerActive ){
        // console.log( `closing ${self.markerActive.id}` );
        self.markerActive.close();
      }
      infoWindow.open({
        anchor: gMark,
        map: self.googleMap
      })
      self.markerActive = infoWindow;
      self.listings.scrollToListing( marker.data.mls );
    });

  },

  /**
   * @method processMarker
   * creates markers for new POIs. provides interactivity for popup modal
   * @param poiData {object} used for building the Google Marker
   */

  enhanceMarker( marker ){

    // modifications to marker depending on type
    if( !this.useAdvancedMarkers ){

      const icon = {
        url: 'https://www.alexsellsrichmond.com/files/media/mls/house-icon-green.png',
        scaledSize: new google.maps.Size( 36, 36 ), // size
        origin: new google.maps.Point( 0, 0 ), // origin
        anchor: new google.maps.Point( 0, 0 ) // anchor
      };

      //store original icon for restoring if changed
      marker._originalIcon = icon;
      marker._originalZindex = marker.googleMarker.getZIndex();
      marker.restoreMarker = () => {
        marker.googleMarker.setIcon( marker._originalIcon );
        marker.googleMarker.setZIndex( marker._originalZindex );
      }

      // marker.markerData.icon = icon;
      marker.googleMarker.setIcon( icon )

    }

  },

  /**
   * @method processMarker
   * creates markers for new POIs. provides interactivity for popup modal
   * @param poiData {object} used for building the Google Marker
   */

  processMarker( poiData ){

    if( DEBUG > 4 ) console.log( 'Processing Marker', this.markerCount );

    const c = this.createCustomMarker( +poiData.mls );

    c.data = poiData;

    const m = this.createMarker( c );

    this.enhanceMarker( m );

    this.createMarkerPopup( m );

    // delete m.data; // attempting to speed up maps

  },

  
  /**
   * @method processMarkers
   * processes entries in map.houses
   */

  processMarkers(){

    if( DEBUG > 2 ) console.log( `Processing ${this.houses.length} markers.` );

    if( !this.houses.length ){
      if( DEBUG ) console.log( 'Cannot process markers: no data provided.' );
      return;
    }
    
    for( const data of this.houses ){
      // only process new markers
      if( !(+data.mls in this.markers) ) this.processMarker( data );
    }

    // console.log( 'map.markers', this.markers );

  },

  /**
   * @method changeMarkerIcon
   * changes the color of an existing marker icon
   * @param mlsId {number} mls number of marker
   * @param color {string} color to make the icon
   * @returns marker {map.marker} provided to call .restoreIcon
   */

  async changeMarkerIcon( mlsId, color ){
    if( this.useAdvancedMarkers ) return; // can't use with advanced yet...
    if( DEBUG ) console.log( 'Changing marker icon for', mlsId );
    if( !this.markers[ mlsId ] ){
      console.warn( `Marker for ${mlsId} not created yet - await map.ready to resolve.` );
      return;
    }
    const m = this.markers[ mlsId ];
    const g = m.googleMarker;
    const c = g.getIcon();
    c.url = `https://www.alexsellsrichmond.com/files/media/mls/house-icon-${color}.png`;
    g.setIcon( c );
    g.setZIndex( 100 );
    return m;
  },

}