const testDirs = [
  "11417 Edgelake Court Glen Allen, VA 23059",
  "1967 Farnborough Drive Midlothian, VA 23112",
  "4 Raven Rock Lane Henrico, VA 23229"
];

const generateTestSet = () => {
  const randomIndex = Math.floor(Math.random() * testDirs.length );
  const testSet = [];
  let i = 1;
  const folder = encodeURIComponent( testDirs[ randomIndex ] );
  while( i <= 50 ){
    const buffer = ( i < 10 ? '00' : '0' ); // buffer string to match /001.jpg naming structure
    testSet.push( `https://www.alexsellsrichmond.com/content/special/mls/listing-photos/${folder}/${buffer}${i++}.jpg` );
  }
  // console.log( testSet );
  return testSet;
}

export default generateTestSet;