import { defineStore } from 'pinia'


export const useGoogleStore = defineStore('google', {
  state: () => {
    return {
      loaded: false,
      instance: null,
      keys: {
        // projectName: apiKey
        'glaserMapsAPIs': 'AIzaSyCGm1ysMoxyHwXe0TqomCtUSYgri8fLrqY', // master key, rest = trash
        'glaserMLS': 'AIzaSyDyFyzAHOKd_v9AwIkz2c04zRghs9RuI8I', 
        'mlsMapBrowserSide': 'AIzaSyBs80oubuydBsmgcG_RZXVtVdZ_2-2ilic',
        'glaserUserSystem': 'AIzaSyCU32Djp_a5R7AdWszJvS0eH7QeOur4754'
      },
    }
  },
  actions: {

    async importScript(){
      return (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        key: this.keys[ 'glaserMapsAPIs' ],
        v: "weekly"
      });
    },

    async load(){
      //handled in two steps to avoid reloading of google script
      if( !this.loaded ){
        this.loaded = true; // eager to prevent duplicate loads
        await this.importScript();        
        // console.log( 'Loading MarkerClusterer' );
        // var script = document.createElement('script');
        // script.src = "https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js";
        // document.body.appendChild(script);       
        // console.log( 'typeof MarkerClusterer:', typeof MarkerClusterer ); 
        window.google = this.instance = google;
        // console.log( 'Google loaded', google );
      }
      return this.instance;
    },

  }
})