<style>
.hide { display: none }
</style>

<template lang="pug">
.mls-filter(
  ref="filter"
)

  .mls-filter-toggle(
    @click="toggleVariables"
  )

    .mls-filter-name Bedrooms

    .mls-filter-type(:class="'mls-filter-type-' + filter.type" )

  .mls-filter-variables(:class="{ open: isFilterOpen }" ref="vars")

    //- handle Radio buttons
    .mls-filter-radio(
      v-if="filter.type === 'radio'"
      v-for="(option, index) in filter.options"
      @click='select($event)'
      @change='change($event)'
    )
      .mls-filter-variable()
        input(
          type='radio'
          :name="filter.name"
          :value="option"
          v-model="bedroomCount"
          :ref="'var'+index"
          :key="'bedroomOption'+index"
        )
        label(
          :for='filter.name'
        ) {{option}}
          template(v-if="filter.name === 'Bedrooms' || filter.name === 'Bathrooms'") +
</template>

<script>
const DEBUG = 0;
export default {
  name: 'Bedrooms',
  props: [ 'filter', 'open', 'state' ],
  data() {
    return {
      isFilterOpen: this.open,
      bedroomCount: this.filter.defaultOption,
      inputs: []
    }
  },
  methods: {

    initFilter(){
      for( const i in this.filter.options ){
        const inputComponent = this.$refs[ 'var' + i ];
        this.inputs.push( inputComponent );
      }
    },

    resetRadios(){
      for( const r in this.inputs ){
        this.inputs[r].checked = false;
      }
    },

    change(event){
      if( DEBUG ) console.log( 'Change triggered' )
      this.bedroomCount = event.target.value;
      this.$emit( 'bedCountChanged', this.bedroomCount );
      this.$emit( 'updateFilterStates', 'bedrooms', this.bedroomCount );
    },

    select(event){
      this.resetRadios();
      let p = event.target.parentElement;
      const i = p.querySelector('input');
      i.checked = true;
      const changeEvent = new Event( "change", { bubbles: true } );
      i.dispatchEvent(changeEvent);
    },

    toggleVariables(){
      var alreadyOpen = this.isFilterOpen;
      if( DEBUG ) console.log( 'alreadyOpen?', alreadyOpen );
      this.$emit( 'variablesToggled' );
      this.isFilterOpen = !this.isFilterOpen; // Toggle visibility
      if( alreadyOpen ) this.closeVariables();
      
    },

    openVariables(){
      if( DEBUG ) console.log( 'opening variables' );
      this.isFilterOpen = true;
    },

    closeVariables(){
      this.isFilterOpen = false;
    },

  },

  mounted(){
    this.initFilter();
  }

}

</script>