const DEBUG = 0;


function createHousePopup( houseData ){
  const h = houseData;
  if( !h ){
    console.log( 'Could not create house popup - no house data provided.' );
    return;
  }
  // situate elements (create and add classes)
  const m = document.createElement('div');
  const c = document.createElement('div');
  c.classList.add('house-popup');
  const a = document.createElement('div');
  a.classList.add('house-popup-address');
  const i = document.createElement('div');
  i.classList.add('house-popup-image');
  // const p = $('<div class="house-popup-price"></div>');
  const bb = document.createElement('div')
  bb.classList.add('house-popup-details');
  const l = document.createElement('div')
  l.classList.add('house-popup-link');

  // inject HTML
  a.innerHTML=( h.street || 'Unknown Street' );
  i.innerHTML=( '<img src="' + h.defaultImage + '" />' );
  // p.html( formatPrice( h.price ) );
  bb.innerHTML=(
    '<div class="beds">' + h.bedrooms + ' Bed</div>'
    + '<div class="baths">' + h.bathrooms + ' Bath</div>'
    // + '<div class="sqft">' + h.footage + ' SqFt</div>'
    + '<div class="price">' + ( h.priceFormatted ) + '</div>'
    //+ '<div class="sqft">' + h.footage + ' ft<sup>2</sup></div>'
  );
  l.innerHTML=( '<a href="' + h.url + '">View Listing</a>' );

  // append to proper elements
  c.appendChild( i );
  c.appendChild( a );
  c.appendChild( bb );
  c.appendChild( l );
  // c.append( p );
  m.appendChild( c );

  // return completed markup
  return m.innerHTML;

}

export default createHousePopup;