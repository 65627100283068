<template lang="pug">

.bottom-bar-container 

  .left
    .settings-area
      .settings-icon.icon(@click="app.toggleLeftBar")
        .icon-wrap
          img(src='~@/assets/media/icon-settings.svg')
        //.icon-label
          | Settings

  .middle
    .status-message(v-if="app.statusMessage !== ''")
      p {{ app.statusMessage }}
    .disclaimer(v-else)
      p Data provided may or may not be accurate.

  .right
    .filters-icon.icon(@click="app.toggleRightBar")
      .icon-wrap
        img(src='~@/assets/media/icon-filters.svg')
      //.icon-label
        | Filters

</template>


<script setup>

import { useAppStore } from '@/store/app'

const app = useAppStore()

</script>

<style lang="scss">

.mls-app.screen-tiny {
  .mls-bottom-bar .disclaimer p {
    font-size: 1.618em;
  }
}

</style>