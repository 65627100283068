function getMapCenter( bounds ){
  return {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2
  }
}

// Function to calculate distance between two points using Haversine formula
function calculateSphericalDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

// Function to calculate distance between a point and the center of a rectangle
function calculateDistanceToRectangleCenter(lat, lng, bounds) {
  const center = getMapCenter( bounds );
  return Math.sqrt((lat - center.lat) ** 2 + (lng - center.lng) ** 2);
}

function calculateDistanceToRectangleEdge(lat, lng, bounds) {
  let dx = Math.max(0, Math.max(bounds.west - lng, lng - bounds.east));
  let dy = Math.max(0, Math.max(bounds.south - lat, lat - bounds.north));
  return Math.sqrt(dx * dx + dy * dy);
}

import { useMapStore } from '@/store/map';

// const map = useMapStore();

class ListingSorter {

  constructor( listingsStore, group = 'all' ) {
    this.listings = listingsStore;
    this.group = group;
  }

  mapView(){
    //console.log( 'mapView fn', this.listings.map ); return;
    // add boundaries
    const bounds = this.listings.map.getBoundaries();
    return this.listings[ this.group ].slice().sort((a, b) => {
      const distanceA = calculateDistanceToRectangleCenter(a.lat, a.lng, bounds);
      const distanceB = calculateDistanceToRectangleCenter(b.lat, b.lng, bounds);
      return distanceA - distanceB;
    });
  }

  edge(){
    //console.log( 'mapView fn', this.listings.map ); return;
    // add boundaries
    const bounds = this.listings.map.getBoundaries();
    return this.listings[ this.group ].slice().sort((a, b) => {
      const distanceA = calculateDistanceToRectangleEdge(a.lat, a.lng, bounds);
      const distanceB = calculateDistanceToRectangleEdge(b.lat, b.lng, bounds);
      return distanceA - distanceB;
    });
  }

  priceDsc() {
    // Implement sorting by price (high to low)
    return this.listings[ this.group ].slice().sort((a, b) => b.price - a.price);
  }

  priceAsc() {
    // Implement sorting by price (low to high)
    return this.listings[ this.group ].slice().sort((a, b) => a.price - b.price);
  }

  nearest() {
    const bounds = this.listings.map.getBoundaries();
    const center = getMapCenter( bounds );
    // Implement sorting by nearest
    return this.listings[ this.group ].slice().sort((a, b) => {
      const distanceA = calculateSphericalDistance(center.lat, center.lng, a.lat, a.lng);
      const distanceB = calculateSphericalDistance(center.lat, center.lng, b.lat, b.lng);
      return distanceA - distanceB;
    });
  }

  featured() {
    const featured = this.listings.featured || [];
    // Implement sorting by featured
    return this.listings[ 'originalListings' ].slice().sort((a, b) => {
      const isInFeaturedA = featured.includes(a.mls);
      const isInFeaturedB = featured.includes(b.mls);
  
      // Sort listings with mlsId in featured array first
      if (isInFeaturedA && !isInFeaturedB) {
        return -1;
      } else if (!isInFeaturedA && isInFeaturedB) {
        return 1;
      } else {
        // If both or neither are in featured, maintain their order
        return 0;
      }
    });
  }

}

export default ListingSorter;
