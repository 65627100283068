<template lang="pug" >

mixin system-item( name, action )
  .system-item(action)
    .name=name
    .rest
      block


.system-management(ref='targetElement')


  .system-item(@click="app.showAdmin")
    .name Account

  +system-item( 'Layout' )
  
    .view-icon(
      v-for="orientation in app.layouts"
      :class="orientation == app.layout ? 'active' : 'inactive'"
      @click="app.changeLayout( orientation ); this.$emit( 'collapseBar' )"
    )
      img(:src="require(`@/assets/media/view-icon-${orientation}.png`)" alt="View Icon")
    

  .system-item
    .name Help 
  //- +system-item( 'Profile' )
  //- +system-item( 'Settings' )
  //- a(href='/help')
    +system-item( 'Help' )
  //- +system-item( 'About' )

</template>


<script>

import { ref } from 'vue';

// import clickOutside from '@/composables/clickOutside.js'

import { useAppStore } from '@/store/app';

export default {
  name: 'LeftBar',
  setup(){
    const targetElement = ref(null);

    const handleClickOutside = (event) => {
      console.log('clickedoutside')
    };

    // clickOutside( handleClickOutside, targetElement );

    return { targetElement };
  },
  data(){
    return {
      app: useAppStore()
    }
  },
  methods: {
    handleClickOutside(){
      console.log('clickedoutside')
    }
  }
}

</script>

<style lang="scss" scoped>
.system-management {

  position: absolute;
  width: 100%;
  bottom: 0;

  .system-item {

    background: $gg-dark;
    padding: .2rem;
    margin-bottom: .2rem;
    cursor: pointer;

    .name {
      color: $gg-lite;
      font-size: 1.2rem;
      margin-bottom: 1em;
      margin: .4em;
    }

    .rest {

    }

  }

}

.view-icon {
  max-width: 128px;
  &.active {
    filter: brightness(.66)
  }
  &.inactive {
    img {
      filter: invert(.66)
    }
  }  
  &:hover {
    img {
      filter: none !important
    }
  }
}



</style>