<template lang="pug">

.photo-viewer(ref="photoViewer")
  .prev-arrow.left-arrow(
    @click='prevPhoto'
    v-if="photos.length > 2"
  )
  .photos(@click="$emit( 'showListing', currIndex )")
    .photo-count(v-if="app.showDevBits") {{ currIndex }} of {{ photos.length }}
    .photo(
      v-if="listing.images.length"
      v-for="photo, index in photos"
      :loading="index == 0 ? 'lazy' : ''"
      :key="photo.id"
      :style="{ backgroundImage: `url(${photo.src})` }"      
      :class="photo.activeClass"
    )
    //@click="photoOverlay"
  .next-arrow.right-arrow(
    @click='nextPhoto'
    v-if="photos.length > 2"
  )
  
</template>

<script>

  const DEBUG = 0;

  import { useAppStore } from '@/store/app';
  import { useListingsStore } from '@/store/listings';
  
  export default {
  
    name: 'PhotoViewer',
  
    props: [ 'listing', 'autoInit'  ],

    data(){
      return {
        app: useAppStore(),
        listings: useListingsStore(),
        photos: [],
        photoCount: 0,
        prevIndex: 0,
        currIndex: 0, 
        nextIndex: 0,        
        classes: {
          ons: 'on-screen',
          osl: 'off-to-left',
          ofr: 'off-to-right',
          ofs: 'off-screen'
        }
      }
    },
  
    methods: {
  
      /**
       * @method preparePhotos
       * converts array of images into photo objects for processing
       */

      preparePhotos(){

        const images = this.listing.images;

        if( DEBUG ) console.log( 'Found ' + images.length + ' images' );
        if( DEBUG > 3 ) console.log( 'Preparing photos', images );
        for( let i in images ){
          if( DEBUG > 2 ) console.log( images[i] );
          this.photos.push({
            id: ++this.photoCount,
            src: null, // will fill when loaded
            data: images[ i ],
            loaded: false,
            activeClass: this.classes.ofs
          })
        }

      },

      /**
       * @method loadImage
       * loads a single image. sets it's src and sets loaded to true, nothing else.
       */

      loadImage( p ){

        if( p.loaded ) return;
        p.src = p.data;
        p.loaded = true

      },

      /**
       * @method abandon
       * accepts an index, moves cooresponding photo off screen
       */

      abandon( n ){

        this.photos[ n - 1 ].activeClass = this.classes.ofs;

      },

      /**
       * @method updatePhoto
       * loads image and modifies activeClass on photo objects 
       */

      updatePhoto( i ){

        const l = this.photos.length;

        if( l == 1 ){
          // make the only photo visible
          this.photos[ 0 ].activeClass = this.classes.ons;
          this.loadImage( this.photos[ 0 ] )
          return;
        }

        const ppi = i === 1 ? this.photoCount : i - 1;
        const npi = i === this.photoCount ? 1 : i + 1;

        const pp = this.photos[ ppi - 1 ];
        const cp = this.photos[ i - 1 ];
        const np = this.photos[ npi - 1 ];

        let currentSet = [ ppi, i, npi ];

        // load current plus adjacent images
        this.loadImage( pp );
        this.loadImage( cp );
        this.loadImage( np );

        // clean up current active photos
        if( !(this.currIndex in currentSet) ) 
          this.abandon( this.currIndex );
        if( !(this.prevIndex in currentSet) ) 
          this.abandon( this.prevIndex );
        if( !(this.nextIndex in currentSet) ) 
          this.abandon( this.nextIndex );
        
        const c = this.classes;
        
        // assign classes to current set
        pp.activeClass = c.osl;
        cp.activeClass = c.ons;
        np.activeClass = c.ofr;

        // update state at end
        this.currIndex = i;
        this.prevIndex = ppi;
        this.nextIndex = npi;

      },

      /**
       * @method prevPhoto
       * reduces currIndex value by 1 (unless 1, which sets value to max index in array)
       */
      
      prevPhoto(){
        
        const i = this.currIndex;
        this.updatePhoto( i === 1 ? this.photoCount : i - 1 );

      },

      /**
       * @method nextPhoto
       * increases currIndex value by 1 (unless max index in array, in which case value is 1)
       */

      nextPhoto(){

        const i = this.currIndex;
        this.updatePhoto( i === this.photoCount ? 1 : i + 1 );

      },

      init(){

        if( !this.listing || !this.listing.images ){
          if( DEBUG > 1 ) console.warn( 'PhotoViewer initialized with no images. Disengaging.' );
          return;
        }
        this.currIndex = 1;        
        this.updatePhoto( this.currIndex );
        // console.log( 'making  pv for', this.listing.address );

      },


  
    },
  
    
  
    created(){
      
      this.preparePhotos();
      
    },
  
    mounted(){
  
      if( this.autoInit ) this.init();

      //console.log( this.app.getListingsObserver() );

      const self = this;

      const doInit = ( entries ) => {
        for( const entry of entries ){
          if( entry.isIntersecting ){
            self.init();
            i.unobserve( self.$refs.photoViewer );
          }
        }
      }

      const i = new IntersectionObserver( doInit, { 
        root: document.querySelector('.mls-listings-wrap'), 
        threshold: .1
      });
      i.observe( this.$refs.photoViewer );
      

      // this.app.getListingsObserver().registerIntersectionExchange({
      //   threshold: 1,
      //   callback: this.init
      // })

      // just attach it to the photo viewer -- simpler that way.
      // this.$refs.photoViewer.initializePhotoViewer = this.init;
      // console.log( this.$refs.photoViewer, this.$refs.photoViewer.initializePhotoViewer, this.init );

  
    }
  
  }
  /*
  /**
   * @method loadWhenVisible
   * @param {*} entries 
   *
  
   loadWhenVisible( entries ){
    for( const entry of entries ){
      const { intersectionRatio } = entry;
      if( intersectionRatio >= 1 ){          
        if( entry.isIntersecting ){
          if( typeof entry.target.initializePhotoViewer == 'function' ) entry.target.initializePhotoViewer()
          else console.log( 'no initializePhotoViewer found for', entry.target.id );
          console.log( entry.target.id, 'in frame and visible' );
        }
      }
    }              
  }
  */
  
  </script>
  
  <style lang="scss" scoped>
  .photo-viewer {
    background-color: $gg-lite;
  }
  .photo-count {
    position: absolute;
    width: 100%;
    color: $gg-gray-dark;
    text-align: center;
    z-index: 1;
  }
  /* .photos {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  
  .photo {
    flex-shrink: 0;
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in-out;
    background-color: #f0f0f0;
  }
  
  .photo-slide-enter-active,
  .photo-slide-leave-active {
    transition: transform 0.3s;
  }
  
  .photo-slide-enter,
  .photo-slide-leave-to {
    transform: translateX(100%);
  }
  
  .photo-slide-enter-to,
  .photo-slide-leave {
    transform: translateX(0);
  } */
  </style>