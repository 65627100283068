<template lang="pug">

.mls-listings-sorter-wrap
  .listings-total
    span Showing&nbsp;
    span.total-listings-visible {{ listingsVisible }}
    span  of&nbsp;
    span.total-listings {{ listingsTotal }}
    //-=locals['listingsHarvesterSuccess'].length
    span  listings
  .listings-sorter
    form
      label
        span Sort by&nbsp;
      select(v-model="listings.sortBy" @change="listings.changeSortBy")
        option(value='featured') Featured
        option(value='priceAsc') Price: low to high
        option(value='priceDsc') Price: high to low
        option(value='mapView') Map View
      //-
        option(value='nearest') Nearest
        option(value='edge') Edge
        option(value='newest') Newest
        option(value='oldest') Oldest

</template>

<script>

import { useListingsStore } from '@/store/listings';

export default {
  name: 'Sorter',
  data(){
    return {
      listings: useListingsStore()
    }
  },
  computed: {

    listingsVisible(){
      return this.listings.active.length;
    },

    listingsTotal(){
      const l = this.listings.all.length;
      return ( l > 999 ) ? '1K+' : ( l || 0 )
    }

  },

  methods: {

    

  },
  
}

</script>

<style>

.listings-total {
  //font-size: 11pt;
}

</style>