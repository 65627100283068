const DEBUG = 0;

export default {

  /**
   * @method squareListings
   * makes .listings width what was previously listing's height
   * @returns 
   */

  squareListings(){
    //const l = document.querySelector('.listing');
    const ls = document.querySelector('.listings');
    const h = ls.clientHeight
    const la = document.querySelectorAll('.listing');
    //console.log( h );
    for( const l of la ) l.style.width = h;
  },

  /**
   * @method modListingsHeight
   * make .mls-main-body the same height as .listing (clientHeight)
   * @DEVNOTE for horizontal layout only    
   * @NOTE requires listings to be ready
   */

  modListingsHeight( reverse ){

    if( reverse ){
      if( DEBUG ) console.log( 'Reversing listings height modifications' );
      document.querySelector('.mls-listings').style.bottom = 'inherit';
      document.querySelector('.mls-main-body').style.gridTemplateRows = `1fr`;
      return;
    }

    if( DEBUG ) console.log( 'Modifying "Listings" height for horizontal layout' );

    this.scrollbarDimensions = this.getScrollBarDimensions();
    if( DEBUG > 1 ) console.log( "Scrollbar Dimensions: ", this.scrollbarDimensions );

    const l = document.querySelector('.listing');

    if( l ){
      const h = l.clientHeight + this.scrollbarDimensions.height;
      if( DEBUG > 1 ) console.log( 'overlap', this.overlapListings );
      if( this.overlapListings ){
        document.querySelector('.mls-listings').style.bottom = `${h}px`;
      }
      else {
        document.querySelector('.mls-main-body').style.gridTemplateRows = `1fr ${h}px`;
        if( DEBUG > 1 ) console.log( 'Setting 2nd row to', h );
      }
    }
  },

  /**
   * @method normalizeListingsStatus
   * make .listings-status the same dimensions as .listing
   * @returns 
   */

  normalizeListingsStatus(){      
    const l = document.querySelector('.listing');
    if( !l ) return;
    if( DEBUG ) console.log( 'Normalizing listing status (making same size as listing)' );
    const s = document.querySelector('.listings-status-inset');
    s.style.width = l.clientWidth + 'px';
    s.style.height = l.clientHeight + 'px';
  },

  /**
   * @method determineViewSize
   * determines the viewSize for .mls-app based on sizeMod startAt pixels
   * @returns 
   */

  determineViewSize(){
    if( DEBUG > 3 ) console.log( 'Determining View Size' );
    //return 'tiny';
    const sm = this.sizeMods;
    if( window.innerWidth < sm.small.startAt ) return 'tiny';
    else {
      let match = false;        
      for( let name in sm ){
        //if( !sm[ name ].startAt ) return 'tiny';
        let matched = window.innerWidth > sm[ name ].startAt;
        if( !match && matched ){
          match = true; //stop on first/largest match
          if( DEBUG > 4 ) console.log( 'View Size:', name );
          return name;
        }
      }    
    }
  },

  /**
   * @method determineOverlap
   * a list of rules that decides if listings should overlap map
   * @returns 
   */

  determineOverlap(){
    if( DEBUG > 2 ) console.log( 'Deciding Overlap for', this.viewSize, this.layout );
    // conditionally set overlapListings boolean
    let overlap = false;
    // do overlap any time listing is greater than 50% of orientation
    const l = document.querySelector('.listing');
    if( l ){
      const mb = document.querySelector('.mls-main-body');
      // if( l.clientHeight > ( mb.clientHeight / 2 ) ) overlap = true;
    }
    const conditions = [
      ( 
        this.viewSize == 'tiny' 
        &&
        this.layout == 'vertical'
      ),
      ( this.viewSize == 'huge' )
    ];
    for( const m of conditions ){
      if( m ) overlap = true;
    }
    if( DEBUG > 1 ) console.log( 'Overlap Listings?', overlap );
    //if( overlap ) this.trigger( 'updatedView' );
    this.overlapListings = overlap;
  },

  /**
   * @method determineLayout
   * if width > height, vertical. else horizontal
   * @returns 
   */

  determineLayout(){
    if( this.layoutForced ) return;
    if( DEBUG > 2 ) console.log( 'Determining layout' );
    const a = document.querySelector('.mls-app');
    const w = a.clientWidth;
    const h = a.clientHeight;
    const layout = ( w > h ) ? 'vertical' : 'horizontal';
    if( DEBUG > 1 ) console.log( 'Determined layout should be', layout, `(${w}w x ${h}h)` );
    //this.changeLayout( layout );
    this.layout = layout; //@DEVNOTE: assign instead of calling changeLayout
  },

  /**
    * @method changeScreenSize
    * determines viewSize, layout, overlap, and calls the callback (if set)
    * @param [callback] {function} optional callback
    * @returns 
    */

  changeScreenSize( callback ){

    if( DEBUG > 4 ) console.log( 'Determining Screen Size' );

    this.viewSize = this.determineViewSize();

    //this.layout = this.sizeMods[ this.viewSize ].defaultLayout || 'vertical';

    //this.determineOverlap();

    this.determineLayout();

    // setTimeout( callback, 1000 );
    if( typeof callback == 'function' ) callback();

  },

  /**
   * @method changeLayout
   * accepts 'horizontal' or 'vertical'. changes app.layout and triggers updatedView
   * @param {string} layout 
   */

  changeLayout( layout ){

    if( DEBUG ) console.log( 'Changing layout to', layout );

    if( this.layouts.indexOf( layout ) > -1 ){
      if( DEBUG > 1 ) console.log( 'layout exists, now changing' );
      this.layoutForced = true;
      this.layout = layout;
      this.trigger( 'updatedView', true );
    }

  },

  getScrollBarDimensions(){
    // Create a dummy element with a scrollbar
    var scrollDiv = document.createElement("div");
    scrollDiv.style.width = "100px";
    scrollDiv.style.height = "100px";
    scrollDiv.style.overflow = "scroll";
    document.body.appendChild(scrollDiv);
  
    // Measure the dimensions of the scrollbar
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    var scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
  
    // Remove the dummy element
    document.body.removeChild(scrollDiv);
  
    return {
      width: scrollbarWidth,
      height: scrollbarHeight
    };
  }
  

}
