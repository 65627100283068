<template>

<div id="confetti-shooter"></div>

</template>


<script setup>

import { useAppStore } from '@/store/app';

import { onMounted } from 'vue'

const app = useAppStore();

let fired = false;

function confettiShooter(){

  if( fired ) return;

  if( typeof confetti != 'function' ){
    setTimeout( confettiShooter, 150 );
    return;
  }

  var count = 200;

  var defaults = {
    origin: { y: 0.7 }
  };

  function fire( particleRatio, opts ){
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio)
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });

  fired = app.fired = true;

}

app.fireConfetti = function(){
  confettiShooter();
}

onMounted(()=>{

  try {

    const s = document.createElement('script');

    s.src = "https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.2/dist/confetti.browser.min.js";

    const c = document.getElementById('confetti-shooter');

    c.appendChild( s );

    //setTimeout( , 1 );

  } catch( e ){
    console.log( 'Shooter could not shoot:', e );
  }

});

</script>

<style scoped>

#confetti-holder{
  position: fixed;
  height: 100vh;
  height: 100dvh;
  pointer-events: none;
  width: 100%;
  top: 0;
  z-index: 2;
  text-align: center;
  vertical-align:middle;
} 

.center {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  background-color: #FFF8F5;
	align-items: center;
  justify-content: center;
}

</style>