const DEBUG = 0;

const filterData = {
  "bedrooms": {
    "id": 0,
    "name": "Bedrooms",
    "type": "radio",
    "options": [
      1,
      2,
      3,
      4,
      5,
      6,
      7
    ],
    "defaultOption": 3
  },
  "bathrooms": {
    "id": 1,
    "name": "Bathrooms",
    "type": "radio",
    "options": [
      1,
      2,
      3,
      4,
      5
    ],
    "defaultOption": 2
  },
  "price": {
    "id": 2,
    "name": "Price",
    "type": "range",
    "options": {
      "min": 50000,
      "max": 10000000      
    },
    "defaultOption": [ 250000, 400000 ]
  },
  "propertyTypes": {
    "id": 3,
    "name": "Property Types",
    "type": "checkbox",
    "options": [
      "House",
      "Condo",
      "Land"
    ],
    "defaultOption": [
      "House",
      "Condo"
    ]
  },
  "garageDesired": {
    "id": 4,
    "name": "Garage Desired/Required",
    "type": "radio",
    "options": [
      "Yes",
      "No",
      "Unsure"
    ],
    "defaultOption": "Unsure",
    "inactive": true
  },
  "test": {
    "id": 5,
    "name": "Test",
    "type": "test",
    "options": [
      "Test"
    ],
    "defaultOption": "Test",
    "inactive": true
  },
  "footage": {
    "id": 6,
    "name": "Footage",
    "type": "number",
    "options": [ 0, 800, 1200, 1600, 2000, 2400, 3000, 4000, 5000 ],
    "defaultOption": 1600
  },
  "timeOnMarket": {
    "id": 6,
    "name": "Time On Market",
    "type": "calendar",
    "options": [
      { "label": "Any", "increase": 0 },
      { "label": "<3 Days", "increase": 3 },
      { "label": "<1 Week", "increase": 7 },
      { "label": "<2 Weeks", "increase": 14 },
      { "label": "<1 Month", "increase": 30 },
      { "label": "<2 Months", "increase": 60 },
      { "label": "<3 Months", "increase": 90 },
      { "label": "90+ Days", "reduce": 90 },
    ],
    "defaultOption": 4
  }
}

const filterDefaults = {
  bedrooms: 3,
  bathrooms: 2,
  footage: 1000,
  priceMin: 250000,
  priceMax: 350000,
  propertyTypes: [ 'house' ]
}

export default {
  state: {
    filterData,
    filterDefaults,
    filtersChanged: false,
    filtersUpdated: false,
    filterSettings: filterDefaults,
  },
  actions: {
    changeFilters( changes ){
      this.filterSettings = { ...this.filterSettings, ...changes  };
      this.filtersChanged = true;
      console.log( 'Filter changes pending', this );
    },
    updateFilters( force ){
      if( this.filtersChanged || force ){
        this.fetch( this.filterSettings );
        this.filtersUpdated = true;
        this.filtersChanged = false;
        console.log( 'Filters updated' );
      } else {
        console.log( 'Cannot update: no changed filters detected' );
      }
    }
  }
}