<template lang="pug">

.pagination-page
  span.page-number {{value}}

</template>


<script>

export default {
  name: 'PaginationBlock',
  props: [ 'value' ]
}

</script>