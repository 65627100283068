<template lang="pug">

.account-detail.form-styles
  .field.form-style-mls
    .key
      label(:for="detail") {{detail}}: 
    .value
      input.value(
        v-model="user[ detail ]" 
        :placeholder="'Enter your ' + detail + ' here.'"
        @input="valueChanging"
        :type="type"
        :name="'user[' + detail + ']'"        
        :autocomplete="autocomplete"
        ref="input"
      )
//-:pattern="pattern"
</template>

<script>

const DEBUG = 0;

import { useUserStore } from '@/store/user'; 
import { useGoogleStore } from '@/store/google';

export default {

  name: 'AccountDetail',
  
  props: [ 'detail' ],

  data(){
    const user = useUserStore();
    return {      
      originalValue: user[ this.detail ],
      valueChanged: false,
      user: user,
      type: 'text',
      pattern: '*',
      autocomplete: this.detail,
      google: useGoogleStore()
    }
  },
  

  created(){
    switch( this.detail ){        
      case 'address': 
        this.type = 'address';
        this.pattern = '';
        this.autocomplete = 'address-line1';  
      break;
      case 'phone': 
        this.type = 'tel';
        this.autocomplete = 'tel';
      break;
      case 'email': 
        this.type = 'email';
        this.pattern = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}';
      break;
    }
  },

  async mounted(){        
    
  },

  methods: {    

    valueChanging(){
      this.beginAutocomplete();
      // console.log( 'Value Changing for', this.detail, '(AccountDetail)' );
      // console.log( this.originalValue, this.user[ this.detail ] );
      this.valueChanged = ( this.originalValue != this.user[ this.detail ] );
      this.$emit( 'valueChanging', this.valueChanged );
    },

    async loadGoogleMapsScript(){
      return (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        key: 'AIzaSyCU32Djp_a5R7AdWszJvS0eH7QeOur4754',
        //key: 'AIzaSyBSpZ95BGSAXhD65w85rLi7ojv6oJW-Ekw',
        //libraries: 'places',
        v: "monthly"
      });
    },

    doAutocomplete( callback ){
      // console.log( 'doing autocomplete' );
      const autocomplete = new google.maps.places.Autocomplete( 
        /** @type {!HTMLInputElement} */ 
        ( document.getElementById( 'autocomplete' ) ), {
          componentRestrictions: { country: "us" },
          fields: ["address_components","formatted_address"]
      });
      google.maps.event.addListener( autocomplete, 'place_changed', () => callback( autocomplete.getPlace() ) );
    },

    async beginAutocomplete(){
      if( this.detail == 'address' ){
        try {
          const g = await this.google.load();
          // console.log( 'Autoaddress activated' );
          //console.log( this.$refs );
          this.$refs.input.id = 'autocomplete';
          const p = google.maps.importLibrary('places')
          //console.log( 'G:', google.maps.places, p ); return;
          this.doAutocomplete(( data ) => {
            if( DEBUG ) console.log( 'autocomplete returned', data );
            const address = data.formatted_address;
            this.user.address = address.slice( 0, address.length - 5 );  //gets rid of ', USA'
            // this.$refs.input.value = address;
            this.valueChanging();
          });
        } catch ( err ){
          console.warn( 'Google Maps not found for autocomplete on account details. ' + err );
        }
      }
    }

  }

}

</script>

<style lang="scss" scoped>

.form-styles {

  margin: 0;

  .form-style-mls {

    input, textarea {
      color: $gg-dark;
      border-bottom-color: $gg-blue-lite;
      //width: auto;
    }

    label, input { 
      // display: inline-block;
      &::placeholder {
        color: transparentize( $gg-dark, .5 );
        font-style: italic;
      }
    }

    label {
      vertical-align: top;
    }

  }
  
}
.account-detail {  

  .field {

    display: grid;
    grid-template: 1fr / .15fr .85fr;
    display: flex;
    margin-right: .4em;
    justify-content: flex-start; 
    align-items: center;
    

    .key {
      //flex-shrink: 1;
      flex-grow: 0;
      width: 80px;
      //background: red;
    }

    .value {
      flex-grow: 1;
      //background: blue;
    }

    label {
      //max-width: 140px;
      //background: teal;
    }

    input {      
      font-size: 1em;
      //@TODO: reenable this for prod -- vue wont stfu
      // width: -moz-available;          
      // width: -webkit-fill-available;  
      // width: fill-available;
      width: stretch;
      margin: 0;
      //background: green;
    }

  }

}

// validation styling 

.value {

  position: relative;

  input + span {
    padding-right: 30px;
  }

  input:invalid + span::after {
    position: absolute;
    content: "✖";
    padding-left: 5px;
  }

  input:valid + span::after {
    position: absolute;
    content: "✓";
    padding-left: 5px;
  }

}

</style>
