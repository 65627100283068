<style>
.hide { display: none }
</style>

<template lang="pug">
//- span Filter {{filter.id}}
.mls-filter(
  v-if="!filter.inactive"
  ref="filter"
  )
  //-@click.self="e => e.target.classList.toggle('selected')"
  .mls-filter-toggle(
    @click="toggleVariables"
  )

    .mls-filter-name {{filter.name}}

    .mls-filter-type(:class="'mls-filter-type-' + filter.type" )

  .mls-filter-variables(:class="{ open: isFilterOpen }")

    //- handle Select options
    select.mls-filter-select(
      v-if="filter.type === 'select'"
      :id="filter.id"
      v-for="option in filter.options"
    )
      option.mls-filter-variable.mls-filter-option(@click='select') {{option}}

    //- handle Check boxes
    .mls-filter-checkbox(
      v-if="filter.type === 'checkbox'"
      v-for="option in filter.options"
    )
      .mls-filter-variable(@click='select')
        input(
          type='checkbox'
          :name="filter.name"
          :value="option"
          @change='change($event)'
        )
        label(
          :for='filter.name'
        ) {{option}}

    //- handle Radio buttons
    .mls-filter-radio(
      v-if="filter.type === 'radio'"
      v-for="option in filter.options"
      @click='select($event)'
      @change='change($event)'
    )
      .mls-filter-variable()
        input(
          type='radio'
          :name="filter.name"
          :value="option"
        )
        label(
          :for='filter.name'
        ) {{option}}
          template(v-if="filter.name === 'Bedrooms' || filter.name === 'Bathrooms'") +

    //- handle Price (replace with Slider)
    .mls-filter-range(
      v-if="filter.type === 'range'"
    )
      //-PriceSlider
      .range-slider(id=id+'-range-slider' ref="slider")
        .range-slider-field
          label(for=id+"-amount")=label
          input(type="text" id=id+"-amount" readonly)
        .range-slider-element
          .range-slider-slide(id=id+"-slider")
        input(name=id+'-min-value' hidden)
        input(name=id+'-max-value' hidden)

    template(
      v-if="filter.type === 'test'"
    )
      h4 I am test.

</template>

<script>

//import {Vue} from 'Vue';
//const eventBus = new Vue();
import 'jquery';
import 'jquery-ui/ui/widgets/slider';

export default {
  name: 'Filter',
  props: [ 'filter' ],
  data() {
    return {
      isFilterOpen: false,
    }
  },
  methods: {
    wasClicked(){
      console.log( this,'was clicked');
      //this.openFilter( this )
    },
    toggleVariables(){
      this.$emit( 'variablesToggled' );
      console.log( 'Toggling Variables' );
      this.isFilterOpen = !this.isFilterOpen; // Toggle visibility
      //- this.$parent.closeAllVariables();
      // this.isFilterOpen ? this.closeVariables() : this.openVariables();
    },
    openVariables(){
      console.log( 'opening variables' );
      this.isFilterOpen = true;
    },
    closeVariables(){      
      console.log( 'closing variables' );
      this.isFilterOpen = false;
    },
    ensureParentClass( element, className ){
      let c = element;
      while( c ){
        if( c.classList.contains( className ) ){
          return c;
        }
        c = c.parentElement;
      }
    },
    dispatchChangeEvent( i ){
      const event = new Event( "change", { bubbles:true } );
      i.dispatchEvent(event);
    },
    resetRadios( container ){
      //ensure .mls-filter-variables
      const p = this.ensureParentClass(
        container,
        'mls-filter-variables'
      );
      const r = p.querySelectorAll('input');
      console.log( r.length )
      r.forEach(( v ) => {
        v.removeAttribute( 'checked' );
        this.dispatchChangeEvent( v );
      });
    },
    select(event){
      //ensure .mls-filter-variable
      const p = this.ensureParentClass(
        event.target,
        'mls-filter-variable'
      );
      const i = p.querySelector('input');
      console.log( 'selected', i );
      if( i ){
        if( i.getAttribute('type') == 'radio' ){
          //this.resetRadios( this.ensureParentClass( p, 'mls-filter-variables' ) );
          this.resetRadios( p );
          i.setAttribute( 'checked', 'true' );
        }
        if( i.getAttribute('type') == 'checkbox' ){
          i.setAttribute( 'checked', !i.getAttribute('checked') );
        }
      }
    },
    change(event){
      console.log( 'something was changed' );
      this.$emit('filterValueChange', event.target.value );
    },
  },
  mounted(){
    //eventBus.$on( 'closeAllVariables', this.closeVariables );
    $(this.$refs.slider).slider();
  }
}

</script>

<style lang="scss">

</style>
