<template lang="pug">

.pagination

  a(href='' class='previous-page page-arrow')
    img(src='https://www.alexsellsrichmond.com/files/media/png/green-arrow-left.png' class='pagination-arrow' alt='Previous Page')
    span(class='arrow-text') Previous

  .pagination-blocks
    template(v-if='totalPages > availableBlocks')
      //- p {{totalPages}} more than {{availableBlocks}}
      //-PaginationBlock(:value='currentPage')
      PaginationBlock(v-for="blockCount in availableBlocks-2" :key="blockCount" :value='blockCount')
      PaginationBlock(value='...' @click='extraPages')
      PaginationBlock(:value='totalPages')
    template(v-else)
      //- p {{totalPages}} less than {{availableBlocks}}
      //div()
      PaginationBlock(v-for="blockCount in availableBlocks" :key="blockCount" :value='blockCount')

  a(href='' class='next-page page-arrow')
    img(src='https://www.alexsellsrichmond.com/files/media/png/green-arrow-right.png' class='pagination-arrow' alt='Next Page')
    span(class='arrow-text') Next

</template>


<script>

import PaginationBlock from './PaginationBlock'

import { useAppStore } from '@/store/app';
const app = useAppStore();

import { useListingsStore } from '@/store/listings';
const listings = useListingsStore();

export default {
  name: 'Pagination',  
  components: {
    PaginationBlock
  },
  data(){
    return {
      blockCount: 0,
      currentPage: 1,
      maxBlocks: 5,
      listingsPerPage: app.listingsPerPage,
      totalListings: listings.total
    }
  },
  methods: {
    changePage(){
      //console.log( 'User clicked' );
      this.$emit( 'changePage', this.page );
    },
    extraPages(){
      console.log( 'Show Extra Pages' );
      //this.$emit( 'changePage', this.page );
    }
  },
  computed: {
    
    totalPages(){

      let totalPages = Math.ceil( this.totalListings / this.listingsPerPage );

      //console.log( 'Total Pages', totalPages )

      return totalPages;

    },
    availableBlocks(){

      let max = this.maxBlocks;

      if( max > this.totalPages ) max = this.totalPages;

      //console.log( 'Max Page Blocks', max )

      return max;

    }

  },
  created(){


  }
}

</script>