const DEBUG = 0;

import { defineStore } from 'pinia'

import { useAppStore } from '../app';

import { useMapStore } from '../map';

import ListingSorter from '@/components/listings/ListingSorter.js'

import generateTestSet from '@/demo/testPhotos';

import filters from './filters';

import fetchers from './fetchers';

import ui from './ui';

//const listingsReady = { promise, resolve, reject };
const listingsReady = { promise: null, resolve: null, reject: null };

listingsReady.promise = new Promise(( resolve, reject ) => {
  listingsReady.resolve = () => {
    if( DEBUG > 2 ) console.log( 'initial listings promise resolved' ); 
    resolve();
  }
  listingsReady.reject = reject;
})

export const useListingsStore = defineStore('listings', {
  state: () => {
    return {
      app: useAppStore(),
      all: [], // holds ALL listings that were fetched
      total: 0, // total for ALL
      active: [], // holds ACTIVE listings based filter steps,
      available: 0, // the total listings IN the MLS databaase
      selected: null,
      demoMode: false,
      useTestListings: false,
      testListingsLimit: 12,
      useTestPhotos: false,
      demoImage: 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
      defaultImage: "http://localhost:8080//assets/media/no-image-available.jpg",
      //activeListings: {},
      status: 'Awaiting listings...',
      loaded: false,      
      filterSettings: null,
      ...filters.state,
      map: useMapStore(),
      sortBy: 'featured',
      _firstPromise: true,
      _ready: {
        resolve: listingsReady.resolve,
        reject: listingsReady.reject
      },
      ready: listingsReady.promise,
      // listings outside of mapBounds should be ignored
      maxBounds: {
        "north": 38.24, // higher number = more north
        "east": -76.78, // lower number = more east (disregard the negative)
        "south": 37.284, // lower number = more south 
        "west": -78.2 // higher number = more west (disregard the negative)
      },
      firstLoad: true,
      zoomOutIfNone: true, //will continue zooming out until max zoom or 1+ listings
      eventStore: {},
      touchStartX: 0, // used for touch controls on .mls-listings-wrap
    }
  },
  actions: {

    ...filters.actions,

    ...fetchers,
    ...ui,

    on( event, callback ){
      if( this.eventStore[ event ] ) this.eventStore[ event ].push( callback );
      else this.eventStore[ event ] = [ callback ];
      if( DEBUG > 3 ) console.log( `${event} registered` );            
    },

    trigger( event, data ){
      if( this.eventStore[ event ] ){
        for( const fn of this.eventStore[ event ] ){
          fn( data );
        }
        if( DEBUG > 2 ) console.log( `${event} triggered` );            
      }
    },

    /**
     * @method changeSortBy
     * reads .sortBy and runs through ListingSorter. replaces .all, triggers filterByBounds
     */
    

    async changeSortBy(){
      if( DEBUG > 2 ) console.log( `Sorting by ${this.sortBy}` );
      const sorter = new ListingSorter( this );
      try {
        let sorted = sorter[ this.sortBy ]();
        // console.log( sorted );
        this.all = sorted;
        await this.filterByBounds();
      } catch( e ){
        throw new Error( `Could not sort by ${this.sortBy} - method does not exist in ListingSorter class. ${e}` );
      }
    },

    async filterListings(){
      //const l = this.all;
      // filter by map bounds
      // await this.googleMaps.load();
      // const map = await this.googleMaps.getMap();
      // console.log( map.getBounds() );
      // const b = this.googleMaps.map.getBounds;
      // console.log( b );
      this.filterByBounds();
      //this.active = l;
    },

    /**
     * @method filterByBounds
     * leaves only listings within map bounds in .active
     */

    async filterByBounds(){
      const boundaries = await this.map.getBoundaries();
      if( DEBUG > 2 ) console.log( `Filtering ${this.active.length} listings by boundary`, boundaries );      
      this.active = this.all.filter( listing => {
        const { lat, lng } = listing;    
        // Check if the listing coordinates are within the boundaries
        return lat >= boundaries.south &&
               lat <= boundaries.north &&
               lng >= boundaries.west &&
               lng <= boundaries.east;
      });
      this.trigger( 'filterByBounds', this.active )
    },

    /**
     * @method update
     * performed when listings are fetched. does legwork and triggers 'update' event
     */
    

    update( listings ){
      if( DEBUG > 2 ) console.log( 'Update called' );
      this.processListingImages( listings );
      this.total = listings.length;
      this.all = listings; // done to keep listings reactive
      // store first listings to use in sortBy `featured`
      if( this.firstLoad ){
        this.originalListings = this.all;
        this.firstLoad = false;
      }
      this.filterListings();
      if( this.total ) this.status = 'Listings loaded!';
      this.loaded = true;
      this._ready.resolve( this.active );
      this.trigger( 'update', this.active );
    },

    /**
     * @method processListingImages
     * applies the appropriate image, generally parsing JSON (string) array from database
     * @param listings 
     */
    

    processListingImages( listings ){

      for( let n in listings ){

        // use test photos from trusted server (useful for demo or testing image features)
        if( this.useTestPhotos ){
          listings[ n ].images = generateTestSet();
          listings[ n ].defaultImage = listings[ n ].images[0];
          continue;
        }

        // use demo image(s) in demo mode (provides no additional photos just the stock one)
        if( this.demoMode ){          
          // console.log( 'Using demo listing image(s)' );
          listings[ n ].images = [ this.demoImage ];
          listings[ n ].defaultImage = this.demoImage;
          continue;
        }
        
        try {          
          // convert stored DB image string to array of image URLs
          if( !this.useTestListings ) listings[ n ].images = JSON.parse( listings[ n ].images );
          listings[ n ].defaultImage = listings[ n ].images[0];
          // console.log( 'Using listing image(s) from database' );
        } catch( e ) {
          // Use fallback with default image (signifies images unavailable)
          // console.log( 'Using default listing image(s) (no DB images available)', e );
          this.images = [ this.defaultImage ];
          listings[ n ].defaultImage = this.defaultImage;
        }
      } 

      return listings;

    },

    /**
     * @method makeReady
     * creates promise (ready) and private reject/resolve (_ready) for listings
     */
    

    makeReady(){
      const promise = new Promise((resolve, reject) => {
        if( DEBUG > 1 ) console.log( 'Creating listings promise.' );
        const proxy = ( x ) => {
          if( DEBUG ) console.log( "Listings are Ready" );
          resolve( x );
        }
        this._ready.resolve = resolve;
        this._ready.resolve = proxy;
        this._ready.reject = reject;        
      })
      this.ready = promise;
    },    

    /**
     * @method init
     * performs initial listings legwork. only called once (on Main component mount)
     */
    
    async init(){

      if( DEBUG > 1 ) console.log( 'listings initializing - map not ready' );

      // make sure map is ready
      await this.map.ready;

      if( DEBUG > 1 ) console.log( 'listings initializing - map is ready' );

      // make sure listing is truely ready
      await this.ready;

      await this.fetchTotalListingCount();

      //app.listingsInfo = `${this.available} listings available.`;

      if( DEBUG ) console.log( 'Initializing listings.' );

      // do initial sortBy operation 
      await this.changeSortBy( this.sortBy );

    }
    
  }
})