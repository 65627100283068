<template lang="pug">
  
.filters-area

  .mls-filters
    Filters(
      @filterValuesChanged="listings.changeFilters"
    )

  .update-filters(:class="{ 'changes-ready': listings.filtersChanged }")
    .update-filters-button(
      title='Make changes to update filters.'
      @click="updateFilters"
    ) Update Filters

</template>

<script>

import { useListingsStore } from '@/store/listings';
import Filters from '@/components/filters/Filters.vue'

export default {
  name: 'RightBar',
  components: {
    Filters
  },
  data(){
    return {
      listings: useListingsStore()
    }
  },
  methods: {
    updateFilters(){
      this.listings.updateFilters();
      this.$emit( 'close' );
    }
  }
}

</script>