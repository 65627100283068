<template lang="pug">

.welcome-overlay(ref="welcome" @keydown="closeOnEsc")
  .welcome-overlay-container(ref='container')
    //- (v-click-outside="console.log('clickedOutside')")
    .wrap
      .content-styles
        h1.welcome-message
          span.strong.bold.c1(style='font-weight:800') Welcome 
          span(style='font-size:100%')
            span to the 
            span.xc2(style='font-weight:800') Glaser MLS 
        p Choose your settings
          span.excess  to start browsing
          |:
        br
        .welcome-filters
          WelcomeFilter(:filter="filters.bedrooms" @filterChanged="filterUpdate = true")
          WelcomeFilter(:filter="filters.bathrooms" @filterChanged="filterChanged = true")
          WelcomeFilter(:filter="filters.price" @filterChanged="filterChanged = true")
        template(v-if="advancedSettings")
          details.advanced-settings.no-marker 
            summary.no-marker  Advanced Settings
            .rest             
              WelcomeFilterRegular(:filter="filters.footage" @filterChanged="filterChanged = true")
              WelcomeFilterRegular(:filter="filters.timeOnMarket" @filterChanged="filterChanged = true")
        template(v-else)
          div(style="padding: 1em")

        .m1e(style="text-align")
          .button.button-standard(
            @click="proceed"
          )
            | Use 
            span {{ buttonQualifier }}
            |  Settings
          

</template>

<script>

///import filterData from './filters/filterData.json';

import { useAppStore } from '@/store/app';
import { useListingsStore } from '@/store/listings';

import WelcomeFilter from './filters/filter/WelcomeFilter.vue';
import WelcomeFilterRegular from './filters/filter/WelcomeFilterRegular.vue';
//import clickOutside from '@/composables/clickOutside';

export default {
  name: 'WelcomeOverlay',
  // prop: [ 'listing' ],
  components: {
    WelcomeFilter,
    WelcomeFilterRegular,
  },
  data(){
    return {
      app: useAppStore(),
      listings: useListingsStore(),
      settings: 'These',
      advancedSettings: false
    }
  },
  computed:{
    buttonQualifier(){
      // return this.listings.filtersChanged ? 'Custom' : 'Default'
      return 'These';
    }
  },
  methods: {
    closeWelcomeOverlay(){
      this.app.welcome = false;
    },
    closeOnEsc( e ){      
      if( e.key === 'Escape' ) this.closeWelcomeOverlay();
    },
    handleClickOutside( container ){
      return ( e ) => {
        if( container.contains( e.target ) ){
          // console.log('Clicked inside the container.');
        } else {
          // console.log('Clicked outside the container.');
          this.app.welcome = false;
        }
      }
    },
    proceed(){
      this.listings.updateFilters( this.app.welcome );
      this.closeWelcomeOverlay();
    }
  },
  async created(){
    this.filters = this.listings.filterData
  },

  async mounted(){
  },

}

</script>

<style lang="scss">

.welcome-overlay {
  
  opacity: 1;

  position: fixed;
  background: transparentize( $gg-gray-dark, .12 );
  z-index: 100;
  width: 100%;
  height: 100%;  
  pointer-events: all;

  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    // height: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 1080px;
  }

  .wrap {    

    border-radius: 2.618em;
    padding: 2.382em 2.618em;

    background-color: $gg-lite;
    margin: 1em;
    width: 100%;
    // height: 100%;
    max-width: calc( 100vw - 2em );
    max-width: calc( 100dvw - 2em );
    max-height: calc( 100vh - 2em );
    max-height: calc( 100dvh - 2em );
    overflow-x: hidden;
    overflow-y: hidden;
    
    box-shadow: 
      0 0 4px 1px transparentize( $gg-lite, 1 )
      ,0 .382em .618em .382em transparentize( $gg-gray-dark, .618 )
    ;

  }

  .welcome-message {

    font-family: "proxima-nova", "Proxima Nova", "Open Sans", "Arial", sans-serif !important;
    font-weight: 400;
    text-transform: initial;

  }

  .button-standard {
    text-align: right;
    //margin-right: 1em auto 0;
    border-radius: 1.618em;
    margin-bottom: 0 !important;

    transition: color 0.2618s cubic-bezier( .18, .16, .76, .85 );
    
    &:hover {
      border-color: lighten( $gg-green, 12 );
    }

    &::before {
      background-image: linear-gradient(to bottom, $gg-green, $gg-green-lite, $gg-green);
      background-image: linear-gradient(to bottom, red 33%, yellow 50%, red 33%) !important;
      background-image: linear-gradient( lighten( $gg-green, 4 ) 0, lighten( $gg-green, 11 ) 33%, darken( $gg-green, 4 ) 66%) !important;
    }

    &::before, &:hover &::before {
      transition-duration: .1618s;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1.618em;
      box-shadow: inset 0 0 4px 0px red;
      //box-shadow: inset 0 0 12px 3px transparentize( $gg-green-lite, .66 );
      box-shadow: inset 0 0 4px 0px transparentize( $gg-green-lite, .66 );
      content: '';
      z-index: 3;
    }
  }

  .welcome-filters {
    display: flex;
    .welcome-filter select {
      margin-bottom: 0;
    }
  }

  .advanced-settings {
    margin: 1.618em 0;
    summary {
      padding: 0;
      color: $gg-blue;
      list-style: none;
      margin-left: 3px;
    }
    .rest {
      margin-top: 1.618em;
    }
  }

}


.screen-tiny, .screen-small {

  .welcome-overlay .wrap {

    max-width: 300px;
    margin: auto;
    text-align: center;

    .button {
      width: 100%;
      text-align: center;
    }

    .welcome-filters {
      display: block;
      .welcome-filter select {
        width: 100%;
        text-align: center;
        option { text-align: center; }
      }
    }

    .excess {
      display: none;
    }

  }

}

</style>