class ListingsObserver {

  constructor(){
    this.observer = null;
    this.listingsInView = [];
    this.visualizations = [];
    this.visualized = false;
    this.exchanges = []; // stores exchanges { threshold: <number>, callback: <function>, data?: <object>, before?: <function>, after?: <function> }
    this.options = {}; // established in setup()
    this.useDefaultHandler = false;
  }

  setup(){

    // console.log( 'Setting up IntersectionObserver' );

    this.options ={
      root: document.querySelector('.mls-listings-wrap'), // Use the viewport as the root    
      threshold: [ 1 ]
    }

    this.observer = new IntersectionObserver( 
      ( entries ) => { this.handleIntersectionExchanges( entries, this ) }, // `this` becomes IntersectionObserver instance in handleIntersectionExchanges
      this.options 
    );

    for( const l of document.querySelectorAll( '.listing' ) ) this.observer.observe( l );

    if( !this.useDefaultHandler ) return;

    // register default exchange
    this.registerIntersectionExchange({
      name: 'defaultHandler',
      data: { nowVisible: [], viewClass: 'in-view', context: this },
      before: ( e, d ) => { 
        for( const l of d.context.listingsInView ) l.classList.remove( d.viewClass );
        d.nowVisible = []; 
        //console.log( 'firing before' );
      },
      callback: ( e, d ) => { 
        // console.log( `pushing ${e.target.id} to nowVisible[]`, d );
        d.nowVisible.push( e.target ) 
      },
      after: ( e, d ) => { 
        for( const n of d.nowVisible ) n.classList.add( d.viewClass );
        d.context.listingsInView = d.nowVisible;  
        // console.log( d.context.listingsInView );  
      },
      threshold: 1
    })

  }

  visualize( threshold, color ){
    const r = this.options.root;
    if( !r._visualized ){
      r._visualized = [];
      r.position = 'relative';
      r.style.boxShadow = 'inset 0 0 10px green';
    } 
    if( r._visualized.includes( threshold ) ) return;
    const b = r.getBoundingClientRect();
    // console.log( b );
    const v = document.createElement('div');
    v.style.position = 'fixed';      
    v.style.top = b.top + 'px';
    v.style.left = b.left + 'px';
    v.style.backgroundColor = color;
    v.style.opacity = .33;
    v.style.width = b.width + 'px';
    v.style.height = ( b.height * threshold ) + 'px';
    r.appendChild( v );
    r._visualized.push( threshold );
    this.visualized = true;
    v._visualization = { root: r };
    // console.log( r._visualized );
    return v;
  }
  
  // this is part of class now and forever?
  adjustInView( entries ){
    const viewClass = 'in-view';
    for( const l of this.listingsInView ) l.classList.remove( viewClass );
    const nowVisible = [];
    for( const entry of entries ){
      const { intersectionRatio,  } = entry;
      if( intersectionRatio >= 1 ){
        nowVisible.push( entry.target )
      }
    }
    for( const n of nowVisible ) n.classList.add( viewClass );
    this.listingsInView = nowVisible;    
  }
    
  /**
   * @method handleIntersectionExchanges
   * basically a registration method for handlers
   * @param {*} entries 
   */
  
  handleIntersectionExchanges( entries, parent ){      
    // console.log( 'Executing exchanges', parent.exchanges );
    for(const x of parent.exchanges ){
      // console.log( x );
      // if( x.name ) console.log( `Executing ${x.name}` );
      if( typeof x.before == 'function' ) x.before( entries, x.data || {} );
      for( const entry of entries ){
        // const { intersectionRatio } = entry;
        // console.log( intersectionRatio );
        if( entry.isIntersecting ){
          x.callback( entry, x.data || {} );
        }
      }
      if( typeof x.after == 'function' ) x.after( entries, x.data || {} );
      
    }
    // handle default exchange all the time...
    this.adjustInView( entries );
  }

  registerIntersectionExchange( exchange, visualize = false ){
    // if( exchange.name ) console.log( `Registering handler "${exchange.name}"` );
    const t = exchange.threshold || 1;
    if( exchange.callback && t ){
      this.exchanges.push( exchange );
    } else console.warn( 'registerIntersectionExchange requires .callback. Default threshold is 1.' );
    if( visualize ) return this.visualize( t, visualize );
  }

}

ListingsObserver.prototype.create = ( threshold ) => {

  const self = new ListingsObserver;

  self.options = {
    root: document.querySelector('.mls-listings-wrap'), // Use the viewport as the root    
    threshold: threshold
  }

  self.observer = new IntersectionObserver( 
    ( entries ) => { self.handleIntersectionExchanges( entries, self ) }, // `this` becomes IntersectionObserver instance in handleIntersectionExchanges
    self.options 
  );

  for( const l of document.querySelectorAll( '.listing' ) ) self.observer.observe( l );

  return self;

}

export default ListingsObserver;