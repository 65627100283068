<template lang="pug">

.listing(
  :id="'listing' + listing.mls" 
  :data-mls="listing.mls"
)

  .block-top
    ListingTop(
      :listing="listing" 
      :userFavorite="isFavoriteListing" 
      @toggleFavoriteStatus="updateUserFavorites"
      :autoInitPhotoViewer="autoInitPhotoViewer"
      @showListing="( i ) => { listings.showListing( listing, i ) }"
    )
  .info
    ListingBottom(
      :listing="listing"
      @showListing="( i ) => { listings.showListing( listing, i ) }"
    )

</template>

<script>

const DEBUG = 1;

import ListingTop from './ListingTop.vue';
import ListingBottom from './ListingBottom.vue';
import PhotoViewer from './photoViewer/PhotoViewer.vue';

import { useAppStore } from '@/store/app';
import { useUserStore } from '@/store/user';
import { useListingsStore } from '@/store/listings';

export default {
  name: 'Listing',
  components: {
    ListingTop,
    ListingBottom,
    PhotoViewer
  },
  props: [ 'listing', 'autoInitPhotoViewer' ],
  data() {
    return {    
      app: useAppStore(), 
      user: useUserStore(), 
      listings: useListingsStore()
    };
  },

  computed: {
    isFavoriteListing(){
      // detect if user has this listing saved/favorited
      return this.user.isSavedListing( this.listing.mls );
    }
  },

  methods: {

    updateUserFavorites(){
      console.log( 'updateUserFavorites called' );
      // prevent feature and show sign-in options
      if( !this.user.signedIn ){
        console.log( 'Not signed in -- showing admin', this.app.adminVisible );
        this.app.showAdmin();
        return;
      }
      // toggle to add or remove listing
      const addOrRemove = !this.isFavoriteListing;
      if( DEBUG ) console.log( 'Toggling favorite status on listing', this.listing.mls, 'for user to', addOrRemove );
      let res = null;
      if( addOrRemove )
        res = this.user.addSavedListing( this.listing.mls );
      else 
        res = this.user.removeSavedListing( this.listing.mls );
      if( res ) res.then(()=>console.log( 'Firebase updated' ))
    }

  }
}

</script>
